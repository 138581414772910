/** Report detail page, holding tabs for analysis, kinematics, and overview */
import {
  IonContent,
  IonIcon,
  IonLabel,
  IonPage,
  IonRouterOutlet,
  IonTabBar,
  IonTabButton,
  IonTabs,
  IonToast,
} from '@ionic/react';
import { analyticsOutline, bodyOutline, informationCircleOutline } from 'ionicons/icons';
import * as React from 'react';
import { useParams } from 'react-router';
import { Redirect, Route } from 'react-router-dom';
import { OryxHeader } from '../../components/Header';
import { useReportContext } from '../../components/reportContext';
import { ReportInfoId, ReportType } from '../../model';
import { OverviewReportTab } from './OverviewReportTab';
import { RunAnalysisReportTab } from './RunReport/RunAnalysisReportTab';
import { RunKinematicsReportTab } from './RunReport/RunKinematicsReportTab';
import { WalkAnalysisReportTab } from './WalkReport/WalkAnalysisReportTab';
import { WalkKinematicsReportTab } from './WalkReport/WalkKinematicsReportTab';

interface ParamTypes {
  id: ReportInfoId;
}
/** Report detail page
 * Loading the report data for the given userId and reportInfoId in the url.
 * Switch the tabs from Walk to Run depending on reportType in the loaded report data.
 * @param reportInfoId - the reportInfoId from the url
 */
export const ReportDetailPage = () => {
  const { id } = useParams<ParamTypes>();
  const { reportInfoDict, reportDict, isLoading, isError, clearError } = useReportContext();
  // console.log(id, reportInfoDict, reportDict);
  const reportInfo = reportInfoDict[id];
  const report = reportInfo && reportInfo.reportId ? reportDict[reportInfo.reportId] : undefined;
  const data = report?.data;
  const isRun = data?.reportType === ReportType.RUN;

  return (
    <IonPage>
      <OryxHeader backTarget='/reports' loading={!!isLoading} />
      <IonContent className='ion-padding' id='main'>
        {report && (
          <IonTabs>
            <IonRouterOutlet id='main'>
              <Route
                path='/reports/:id/analysis'
                render={(props) => {
                  return isRun ? (
                    <RunAnalysisReportTab {...props} report={report} />
                  ) : (
                    <WalkAnalysisReportTab {...props} report={report} />
                  );
                }}
                exact={true}
              />
              <Route
                path='/reports/:id/kinematics'
                render={(props) => {
                  return isRun ? (
                    <RunKinematicsReportTab {...props} report={report} />
                  ) : (
                    <WalkKinematicsReportTab {...props} report={report} />
                  );
                }}
                exact={true}
              />
              <Route
                path='/reports/:id/overview'
                render={(props) => <OverviewReportTab {...props} report={report} />}
                exact={true}
              />
              <Route path={`/reports/${id}`} render={() => <Redirect to={`/reports/${id}/analysis`} />} exact={true} />
            </IonRouterOutlet>

            <IonTabBar slot='bottom'>
              {data && (
                <IonTabButton tab='analysis' href={`/reports/${id}/analysis`}>
                  <IonIcon icon={bodyOutline} />
                  <IonLabel>Analysis</IonLabel>
                </IonTabButton>
              )}
              {data && (
                <IonTabButton tab='kinematics' href={`/reports/${id}/kinematics`}>
                  <IonIcon icon={analyticsOutline} />
                  <IonLabel>Kinematics</IonLabel>
                </IonTabButton>
              )}
              <IonTabButton tab='overview' href={`/reports/${id}/overview`}>
                <IonIcon icon={informationCircleOutline} />
                <IonLabel>Overview</IonLabel>
              </IonTabButton>
            </IonTabBar>
          </IonTabs>
        )}
      </IonContent>

      <IonToast
        isOpen={isError ? true : false}
        onDidDismiss={() => clearError()}
        message={isError && isError.message}
        color='danger'
        position='bottom'
        buttons={[
          {
            text: 'Close',
            role: 'cancel',
          },
        ]}
      />
    </IonPage>
  );
};
