/** Walk kinematics report tab */
import { IonChip, IonCol, IonContent, IonGrid, IonIcon, IonItem, IonPage, IonRow } from '@ionic/react';
import { pricetagsOutline } from 'ionicons/icons';
import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import WalkEnd from '../../../assets/avatars/walk/kinematics/End.png';
import HeelOff from '../../../assets/avatars/walk/kinematics/HeelOff.png';
import InitialContact from '../../../assets/avatars/walk/kinematics/InitialContact.png';
import Midstance from '../../../assets/avatars/walk/kinematics/Midstance.png';
import Midswing from '../../../assets/avatars/walk/kinematics/Midswing.png';
import ToeOff from '../../../assets/avatars/walk/kinematics/ToeOff.png';
import { Report, WalkReportData } from '../../../model';
import { AnkleGraphs, HipGraphs, KneeGraphs, PelvisGraphs } from '../components/KinematicsGraph';
import { RomTable, SpatioTemporalTable } from '../components/KinematicsTable';
import { GraphReportSection, ReportTableSection } from '../components/ReportSection';

const Info: React.FC = () => {
  return <p>Under construction - Coming soon!</p>;
};

/* Avatars in the order as shown on the x-axis */
const avatars = [HeelOff, ToeOff, Midswing, InitialContact, Midstance, WalkEnd];

type WalkKinematicsReportProps = RouteComponentProps & {
  report: Report;
};

/** Walk kinematics report tab
 * @param report - The full walk report info and data
 */
export const WalkKinematicsReportTab = ({ report }: WalkKinematicsReportProps) => {
  const data = report.data as WalkReportData | undefined;

  if (!data) {
    return <div>No kinematics data present.</div>;
  }

  return (
    <IonPage>
      <IonContent className='ion-padding'>
        {report.tags.length > 0 && (
          <IonItem>
            <IonIcon icon={pricetagsOutline} slot='start' />
            {report.tags.map((tag) => (
              <IonChip key={tag}>{tag}</IonChip>
            ))}
          </IonItem>
        )}

        {data && (
          <IonGrid>
            <IonRow class='no-margin'>
              <IonCol>
                <GraphReportSection
                  title='Pelvis'
                  info={<Info />}
                  graph={<PelvisGraphs data={data} avatars={avatars} />}></GraphReportSection>
              </IonCol>
            </IonRow>

            <IonRow class='no-margin'>
              <IonCol>
                <GraphReportSection
                  title='Hip'
                  info={<Info />}
                  graph={<HipGraphs data={data} avatars={avatars} />}></GraphReportSection>
              </IonCol>
            </IonRow>

            <IonRow class='no-margin'>
              <IonCol>
                <GraphReportSection
                  title='Knee'
                  info={<Info />}
                  graph={<KneeGraphs data={data} avatars={avatars} />}></GraphReportSection>
              </IonCol>
            </IonRow>

            <IonRow class='no-margin'>
              <IonCol>
                <GraphReportSection
                  title='Ankle'
                  info={<Info />}
                  graph={<AnkleGraphs data={data} avatars={avatars} />}></GraphReportSection>
              </IonCol>
            </IonRow>

            <IonRow class='no-margin'>
              <IonCol size-md='12' size-lg='6'>
                <ReportTableSection title='ROM Table' info={<Info />}>
                  <IonGrid class='ion-no-padding'>
                    <IonRow>
                      <IonCol>
                        <RomTable data={data} />
                      </IonCol>
                    </IonRow>
                  </IonGrid>
                </ReportTableSection>
              </IonCol>

              <IonCol size-md='12' size-lg='6'>
                <ReportTableSection title='Spatio temporal parameters' info={<Info />}>
                  <IonGrid class='ion-no-padding'>
                    <IonRow>
                      <IonCol>
                        <SpatioTemporalTable data={data} />
                      </IonCol>
                    </IonRow>
                  </IonGrid>
                </ReportTableSection>
              </IonCol>
            </IonRow>
          </IonGrid>
        )}
      </IonContent>
    </IonPage>
  );
};
