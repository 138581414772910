/** Kinematic data upload page */
import {
  IonButton,
  IonCol,
  IonContent,
  IonGrid,
  IonInput,
  IonItem,
  IonLabel,
  IonList,
  IonNote,
  IonPage,
  IonRow,
  IonText,
  IonToast,
} from '@ionic/react';
import * as React from 'react';
import { useState } from 'react';
import { useAuthContext } from '../components/authContext';
import { ErrorToast } from '../components/ErrorToast';
import { FileInput } from '../components/FileInput';
import { OryxHeader } from '../components/Header';
import { FileData, useFirebaseUpload } from '../hooks/useFirebaseUpload';

/** Kinematic data upload page
 * Using the useFirebaseUpload hook to handle the actual upload to cloud storage
 */
export const UploadPage: React.FC = () => {
  // setting up the hook to upload file and track its progress
  const { dataResponse, isLoading, isError, progress, setUploadData, clearError } = useFirebaseUpload();
  const user = useAuthContext().state.userState?.user;

  const [formSubmitted, setFormSubmitted] = useState(false);

  const [tags, setTags] = useState('');

  const [file, setFile] = useState<FileData>();

  const handleFileSelect = (e: any) => {
    e.target.files.length > 0 && setFile(e.target.files[0]);
  };

  const handleTagsSelect = (e: any) => {
    setTags(e.detail.value);
  };

  // The actual file upload handler once you click Submit in the form
  const upload = async (e: React.FormEvent) => {
    e.preventDefault();
    setFormSubmitted(true);

    if (!!user && !!file) {
      const user_id = user.uid;

      // Perform the acutal file upload using the hook
      await setUploadData({ user_id, tags, file });
    }
  };

  return (
    <IonPage>
      <OryxHeader backTarget='/reports' loading={isLoading && !!progress} progress={progress?.value} />

      <IonContent className='ion-padding' id='main'>
        <IonText>
          <h1>Kinematics Upload</h1>
          <p>Please upload the kinematics data.</p>
        </IonText>

        <IonToast
          isOpen={dataResponse ? true : false}
          message={dataResponse && 'Kinematics uploaded to the portal'}
          position='bottom'
          color='success'
          duration={2500}
        />

        <form noValidate onSubmit={upload}>
          <IonGrid>
            <IonRow>
              <IonCol size-lg='6' size-md='8' size-sm='10' size='12'>
                <IonList>
                  <IonItem lines='inset'>
                    <IonLabel position='floating' color='primary'>
                      Tags
                    </IonLabel>
                    <IonInput
                      name='tags'
                      type='text'
                      value={tags}
                      spellCheck={false}
                      autocapitalize='off'
                      onIonChange={handleTagsSelect}></IonInput>
                    <IonNote>Separate multiple tags by a comma.</IonNote>
                  </IonItem>

                  <FileInput onChange={handleFileSelect} />

                  {formSubmitted && !file && (
                    <IonText color='danger'>
                      <p className='ion-padding-start'>Kinematics file is required</p>
                    </IonText>
                  )}
                </IonList>
              </IonCol>
            </IonRow>

            <IonRow>
              <IonCol offset-lg='4' size-lg='2' offset-md='5' size-md='3' offset-sm='6' size-sm='4' offset='6' size='6'>
                <IonButton type='submit' expand='block'>
                  Submit
                </IonButton>
              </IonCol>
            </IonRow>
          </IonGrid>
        </form>

        {/* Debugging: show metadata from the upload
        <pre style={{ fontSize: "smaller" }}>
          {JSON.stringify(dataResponse, null, 2)}
        </pre>
        */}
      </IonContent>

      <ErrorToast message={isError ? isError.message : ''} clearError={() => clearError()} />
    </IonPage>
  );
};
