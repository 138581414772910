import React from 'react';
import {
  IonCardHeader,
  IonCardTitle,
  IonGrid,
  IonRow,
  IonCol,
  IonImg,
  IonSlide,
  IonSlides,
  IonIcon,
} from '@ionic/react';
import './modalindex.css';
import IBimageSwinglegretraction1 from './assets/IBimageSwinglegretraction1.png';
import IBimageSwinglegretraction2 from './assets/IBimageSwinglegretraction2.png';
import { chevronDownOutline, chevronUpOutline } from 'ionicons/icons';

const slideOpts = {
  initialSlide: 0,
  speed: 400,
  direction: 'vertical',
};

export const ModalSwingLegRetraction: React.FC = () => {
  return (
    <>
      <IonCardHeader className='modal_section-header'>
        <div>
          <IonCardTitle>Swing-leg retraction</IonCardTitle>
        </div>
      </IonCardHeader>

      <IonSlides pager={true} options={slideOpts} mode='ios'>
        <IonSlide>
          <IonGrid>
            <IonRow class='ion-align-items-center'>
              <IonCol size='12' size-lg='7' size-xl='7'>
                <IonGrid>
                  <IonRow>
                    <IonCol>
                      <div>
                        <IonImg src={IBimageSwinglegretraction1} class='modalAvatar_medium' />
                      </div>
                    </IonCol>
                  </IonRow>
                  <IonRow>
                    <IonCol>
                      <div className='onderschrift'>
                        Above shows the spring-mass model with retraction. Swing-leg retraction in running is modeled
                        assuming a constant rotation velocity of the leg (retraction speed &#969;<sub>R</sub>), starting
                        at the apex of the flight phase at retraction angle &#966;<sub>R</sub>. Depending on the
                        duration of the flight phase, the landing angle of the leg (angle of attack &#966;<sub>0</sub>)
                        is a result of the model dynamics and has no predefined constant value. The axial leg operation
                        during the stance phase is approximated by a linear spring of constant stiffness (also see: knee
                        flexion at midswing and knee extension at pre-swing).
                      </div>
                    </IonCol>
                  </IonRow>
                </IonGrid>
              </IonCol>
              <IonCol>
                <div>
                  While at recovery the leg takes the shortest path forward, it chooses to make a detour after midswing:
                  the knee swings a long way in front of the hip and then moves back again to end up almost below the
                  hip at landing. This detour is very energy consuming but is worth the investment because it serves a
                  twofold purpose: 1) swing-leg retraction is a simple but effective way of correcting errors on landing
                  (preflex control) and 2) the succession of hip, knee and ankle extension before the foot plant creates
                  the pre-tensioning required when landing.
                  <br></br>
                  <br></br>
                  This reverse movement of the swing leg is initiated in the hip by the <i>m. gluteus maximus</i>.
                  Tension is further influenced by knee extension, which increases tension in the hamstrings but also
                  releases the <i>m. recuts femoris</i>, and hip extension, which has the opposite effect on both
                  (groups of) muscles.
                </div>
              </IonCol>
            </IonRow>
            <IonRow class='ion-hide-xl-down'>
              <IonCol class='ion-float-right'>
                <IonIcon icon={chevronDownOutline} className='Modalicon' />
              </IonCol>
            </IonRow>
          </IonGrid>
        </IonSlide>

        <IonSlide>
          <IonGrid>
            <IonRow class='ion-hide-xl-down'>
              <IonCol class='ion-float-right'>
                <IonIcon icon={chevronUpOutline} className='Modalicon' />
              </IonCol>
            </IonRow>
            <IonRow class='ion-align-items-center'>
              <IonCol size='12' size-lg='7' size-xl='7'>
                <div>
                  <IonImg src={IBimageSwinglegretraction2} class='modalAvatar' />
                </div>
              </IonCol>
              <IonCol>
                <div>
                  The sum of increased and reduced muscle tension is also influenced by the change in the effort arms
                  when the hip and knee angle changes. This creates a complex interplay of changing joint torques which
                  should lead to optimum adjustment of the whole system on landing.
                  <br></br>
                  <br></br>
                  The ability to create pre-tension is considered to be the most limiting factor for maximum running
                  speed. The pre-tension is also an important pre-condition which is required for forward propulsion
                  force.
                  <br></br>
                  <br></br>
                  Swing leg-retraction is closely related to the swing-leg recovery of the opposite leg; together they
                  enable a fierce execution of the scissors.
                </div>
              </IonCol>
            </IonRow>
          </IonGrid>
        </IonSlide>
      </IonSlides>
    </>
  );
};
