/** Component to create the Status and Symmetry fields used in reports */
import { IonItem, IonLabel, IonList } from '@ionic/react';
import * as React from 'react';
import { Attractors } from '../../../../model';
import './index.css';

/** Convert attractors report data for use in the UI
 * Transpose the attractors report data from colId > row of attractors
 * to rows of attractor labels.
 */
export type AttractorLabels = string[][];
export function toAttractorLabels(data: Attractors): AttractorLabels {
  const labels = [
    ['Positive', 'Negative'], // Positive Running - Toe off (0)
    ['Sufficient', 'Absent'], // Ankle Stiffness (1)
    ['Reactive', 'No extension', 'Hyperext.'], //Knee Extension - preswing (2)
    ['Lock', 'Ok', 'Drop'], // Hip Lock (3)
    ['Straight', 'Round'], // Rear Pendulum (4)
    ['Fierce', 'Slow'], // Swingleg Recovery (5)
    ['OK', 'Obtuse'], // Knee angle - Midswing (6)
    ['Positive', 'Negative'], // Positive Running - Midstance (7)
    ['OK', 'Poor'], // Projection Footplant (8)
    ['Active', 'Passive'], // Swingleg Retraction (9)
    ['OK', 'Fair', 'Late'], // Scissors (10)
    ['OK', 'Poor'], // Footplant from above (11)
    ['Ok', 'No'], // Respect the sagittal plane - Ankle (12)
    ['Ok', 'No'], // Respect the sagittal plane - Knee (13)
    ['Ok', 'Collapse'], //Knee flexion at Midstance (14)
  ];
  return data.col0.map((_value: number, rowIdx: number) => {
    return Object.keys(data)
      .sort()
      .map((colId: string) => {
        const labelIdx = data[colId][rowIdx] - 1;
        return labels[rowIdx][labelIdx];
      });
  });
}

type FieldProps = {
  value: string | string[];
  children: React.ReactNode;
};

/** Field component with a heading, line, and one or two fields
 * @param value - The field value, can be a tuple or a single field value
 * @param children  - The heading, e.g., <IonLabel>My field</IonLabel>.
 */
export const Field = ({ value, children }: FieldProps) => {
  return (
    <IonList>
      <IonItem class='ion-no-padding ion-text-center'>{children}</IonItem>
      <IonItem lines='none' class='ion-no-padding ion-text-center'>
        {Array.isArray(value) ? (
          <IonLabel color='primary' className='ion-text-wrap'>
            {value[0]}
            <span className='separator'>|</span>
            {value[1]}
          </IonLabel>
        ) : (
          <IonLabel color='primary' className='ion-text-wrap'>
            {value}
          </IonLabel>
        )}
      </IonItem>
    </IonList>
  );
};

type NameFieldProps = {
  name: string;
  value: string | string[];
};

/** Field component with a title parameter
 * @param name - The tile of the field
 * @param value - Field value, can be a tuple or a single field value
 */
export const NameField = ({ name, value }: NameFieldProps) => {
  return (
    <Field value={value}>
      <IonLabel className='ion-text-wrap'>{name}</IonLabel>
    </Field>
  );
};

/** Component to draw the status dot with color set by the status field */
type StatusProps = { status: number };
export const StatusDot = ({ status }: StatusProps) => {
  const colors = ['red', 'orange', 'green'];
  const styles = {
    backgroundColor: colors[status],
  };
  return <div className='status-dot' style={styles} />;
};

type DotFieldProps = NameFieldProps & {
  status: number;
};

/** Field component with a title, status dot, and field value */
export const StatusDotField = ({ name, value, status }: DotFieldProps) => {
  return (
    <Field value={value}>
      <IonLabel>{name}</IonLabel>
      <StatusDot status={status} />
    </Field>
  );
};
