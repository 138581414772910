import React from 'react';

import { View, StyleSheet, Text, Image } from '@react-pdf/renderer';
import pelvis from '../../../../../assets/avatars/walk/analysis/Pelvis.png';
import hip from '../../../../../assets/avatars/walk/analysis/Hip.png';
import knee from '../../../../../assets/avatars/walk/analysis/Knee.png';
import ankle from '../../../../../assets/avatars/walk/analysis/Ankle.png';
import { StatusDotField } from './PDFFields';
import { Report, WalkReportData } from '../../../../../model';
import { toMatrix, transpose } from '../../../../../hooks/useReports/transform';

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    borderWidth: 1,
    borderColor: '#E2E2E2',
    borderRadius: 4,
    borderStyle: 'solid',
    justifyContent: 'space-between',
    width: '90%',
    padding: 20,
    marginHorizontal: '24px',
    alignSelf: 'center',
    marginVertical: '10px',
  },
  Title: {
    fontSize: 14,
    fontWeight: 'bold',
    fontFamily: 'Montserrat',
  },
  strideDuration: {
    flexDirection: 'row',
    borderBottomWidth: 1,
    borderBottomColor: '#E2E2E2',
    padding: 10,
    width: '500px',
    alignSelf: 'stretch',
    alignItems: 'center',
  },
  Label: {
    fontSize: 10,
    fontFamily: 'Montserrat',
  },
  MobilityContainer: {
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'wrap',
    alignContent: 'space-around',
    marginTop: '10px',
  },
  avatar: {
    width: '70px',
    alignSelf: 'center',
  },
  joint: {
    color: '#1649d8',
    paddingHorizontal: 40,
    textAlign: 'center',
    fontSize: 10,
    fontFamily: 'Montserrat',
    flexGrow: 4,
  },
  mobilityOutputContainer: {
    flexDirection: 'row',
    flexWrap: 'nowrap',
  },
  SpeedLabel: {
    backgroundColor: 'green',
    borderRadius: '10px',
    paddingTop: '2px',
    paddingBottom: '2px',
    paddingHorizontal: '20px',
    color: '#ffffff',
    marginLeft: '20px',
    fontSize: 10,
    fontFamily: 'Montserrat',
  },
  warning: {
    backgroundColor: 'orange',
    borderRadius: '10px',
    paddingTop: '2px',
    paddingBottom: '2px',
    paddingHorizontal: '20px',
    color: '#ffffff',
    marginLeft: '20px',
    fontSize: 10,
    fontFamily: 'Montserrat',
  },
  danger: {
    backgroundColor: 'red',
    borderRadius: '10px',
    paddingTop: '2px',
    paddingBottom: '2px',
    paddingHorizontal: '20px',
    color: '#ffffff',
    marginLeft: '20px',
    fontSize: 10,
    fontFamily: 'Montserrat',
  },
});

type WalkReportProps = {
  report?: Report;
};

//Mobility Card with an Avatar, title, status dots & Range of motion
type MobilityProps = {
  title: string;
  img: string;
  romRow: number[];
  statusRow: number[];
};

/** Mobility report card content, showing avatar, left/right status and symmetry fields
 * @param img - The avatar
 * @param romRow - list of ROM data values
 * @param statusRow - list of status field tuples for (Left, Right)
 */
const Mobility = ({ title, img, romRow, statusRow }: MobilityProps) => (
  <View style={styles.MobilityContainer}>
    <Image style={styles.avatar} src={img} />
    <Text style={styles.joint}>{title}</Text>
    <View style={styles.mobilityOutputContainer}>
      <StatusDotField name={'Left'} value={romRow[0].toFixed(0) + '°'} status={statusRow[0]} />
      <StatusDotField name={'Right'} value={romRow[1].toFixed(0) + '°'} status={statusRow[1]} />
    </View>
  </View>
);

export const PDFMobilityStatus = ({ report }: WalkReportProps) => {
  const data = report?.data as WalkReportData;
  const romData = transpose(data.rom);
  const statusData = transpose(data.mobilityStatus);
  const tableData: number[][] = toMatrix(data.spatioTemporal);
  const speedLabel = () => {
    if (tableData[0][1] === 1) {
      return 'Ok';
    } else if (tableData[0][1] === 2) {
      return 'Slow';
    } else if (tableData[0][1] === 3) {
      return 'Too slow';
    } else {
      return 'Too fast';
    }
  };

  return (
    <View style={styles.container}>
      <Text style={styles.Title}>Mobility Status</Text>
      <View style={styles.strideDuration}>
        <Text style={styles.Label}>Stride Duration: {tableData[0][0].toFixed(2)}s.</Text>
        {tableData[0][1] === 1 && <Text style={styles.SpeedLabel}>{speedLabel()}</Text>}
        {tableData[0][1] === 2 && <Text style={styles.warning}>{speedLabel()}</Text>}
        {tableData[0][1] === 3 && <Text style={styles.danger}>{speedLabel()}</Text>}
        {tableData[0][1] === 4 && <Text style={styles.danger}>{speedLabel()}</Text>}
      </View>
      <Mobility title={'Pelvis'} img={pelvis} romRow={romData[0]} statusRow={statusData[0]} />
      <Mobility title={'Hips'} img={hip} romRow={romData[1]} statusRow={statusData[1]} />
      <Mobility title={'Knees'} img={knee} romRow={romData[4]} statusRow={statusData[2]} />
      <Mobility title={'Ankles'} img={ankle} romRow={romData[7]} statusRow={statusData[3]} />
    </View>
  );
};
