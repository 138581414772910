/** Line graphs for the kinematics walk and run reports */
import { IonCardSubtitle, IonCardTitle, IonCol, IonGrid, IonRow } from '@ionic/react';
import * as React from 'react';
import { Line } from 'recharts';
import { ReportData } from '../../../../model';
import { LineGraph } from '../Graph';
import './index.css';

type KinematicsGraphsProps = {
  data: ReportData;
  avatars: string[];
};

/** Component with a group of 4 line graphs, with avatars and an x-axis on top
 * The component shows reference lines and avatars based on the pointsOfInterests data.
 * Lines are shown based on kinematicsData.
 * @param data - all report data
 * @param avatars - rows of avatar images
 */
// TODO: Now using fixed with and height with the Recharts ResponsiveContainer
// which triggers a warning. Move to flexible width, still aligning the avatars.
// Note the ResponsiveContainer is needed to avoid Recharts bug in resizing the chart
// with a Legend.
export const PelvisGraphs = ({ data }: KinematicsGraphsProps) => {
  return (
    <>
      <IonGrid class='ion-no-padding'>
        <IonRow>
          <IonCol size='4'>
            <IonCardTitle className='graphTitle'>Sagittal</IonCardTitle>
            <IonCardSubtitle className='graphSubtitle'>Anterior Tilt (-) / Posterior Tilt (+)</IonCardSubtitle>
          </IonCol>
          <IonCol size='4'>
            <IonCardTitle className='graphTitle'>Frontal</IonCardTitle>
            <IonCardSubtitle className='graphSubtitle'>Drop (-) / Hike (+)</IonCardSubtitle>
          </IonCol>
          <IonCol size='4'>
            <IonCardTitle className='graphTitle'>Transversal</IonCardTitle>
            <IonCardSubtitle className='graphSubtitle'>Backward Rotation (-) / Forward Rotation (+)</IonCardSubtitle>
          </IonCol>
        </IonRow>

        <IonRow>
          <IonCol class='ion-no-padding' size='4'>
            <LineGraph
              data={data}
              dataKey='kinematicData'
              syncId='kinematics'
              yLabel='Angle (degrees)'
              xLabel='Percentage of stride time'
              width={'99%'}
              height={200}>
              <Line type='monotone' name='Left Pelvis' dataKey='col0' stroke='blue' dot={false} />
              <Line type='monotone' name='Right Pelvis' dataKey='col7' stroke='green' dot={false} />
            </LineGraph>
          </IonCol>

          <IonCol class='ion-no-padding' size='4'>
            <LineGraph
              data={data}
              dataKey='kinematicData'
              syncId='kinematics'
              yLabel='Angle (degrees)'
              xLabel='Percentage of stride time'
              width={'99%'}
              height={200}>
              <Line type='monotone' name='Left Pelvis' dataKey='col8' stroke='blue' dot={false} />
              <Line type='monotone' name='Right Pelvis' dataKey='col9' stroke='green' dot={false} />
            </LineGraph>
          </IonCol>

          <IonCol class='ion-no-padding' size='4'>
            <LineGraph
              data={data}
              dataKey='kinematicData'
              syncId='kinematics'
              yLabel='Angle (degrees)'
              xLabel='Percentage of stride time'
              width={'99%'}
              height={200}>
              <Line type='monotone' name='Left Pelvis' dataKey='col16' stroke='blue' dot={false} />
              <Line type='monotone' name='Right Pelvis' dataKey='col17' stroke='green' dot={false} />
            </LineGraph>
          </IonCol>
        </IonRow>
      </IonGrid>
    </>
  );
};

export const HipGraphs = ({ data }: KinematicsGraphsProps) => {
  return (
    <>
      <IonGrid class='ion-no-padding'>
        <IonRow>
          <IonCol size='4'>
            <IonCardTitle className='graphTitle'>Sagittal</IonCardTitle>
            <IonCardSubtitle className='graphSubtitle'>Extension (-) / Flexion (+)</IonCardSubtitle>
          </IonCol>
          <IonCol size='4'>
            <IonCardTitle className='graphTitle'>Frontal</IonCardTitle>
            <IonCardSubtitle className='graphSubtitle'>Abduction (-) / Adduction (+)</IonCardSubtitle>
          </IonCol>
          <IonCol size='4'>
            <IonCardTitle className='graphTitle'>Transversal</IonCardTitle>
            <IonCardSubtitle className='graphSubtitle'>External Rotation (-) / Internal Rotation (+)</IonCardSubtitle>
          </IonCol>
        </IonRow>

        <IonRow>
          <IonCol class='ion-no-padding' size='4'>
            <LineGraph
              data={data}
              dataKey='kinematicData'
              syncId='kinematics'
              yLabel='Angle (degrees)'
              xLabel='Percentage of stride time'
              width={'99%'}
              height={200}>
              <Line type='monotone' name='Left Hip' dataKey='col1' stroke='blue' dot={false} />
              <Line type='monotone' name='Right Hip' dataKey='col2' stroke='green' dot={false} />
            </LineGraph>
          </IonCol>

          <IonCol class='ion-no-padding' size='4'>
            <LineGraph
              data={data}
              dataKey='kinematicData'
              syncId='kinematics'
              yLabel='Angle (degrees)'
              xLabel='Percentage of stride time'
              width={'99%'}
              height={200}>
              <Line type='monotone' name='Left Hip' dataKey='col10' stroke='blue' dot={false} />
              <Line type='monotone' name='Right Hip' dataKey='col11' stroke='green' dot={false} />
            </LineGraph>
          </IonCol>

          <IonCol class='ion-no-padding' size='4'>
            <LineGraph
              data={data}
              dataKey='kinematicData'
              syncId='kinematics'
              yLabel='Angle (degrees)'
              xLabel='Percentage of stride time'
              width={'99%'}
              height={200}>
              <Line type='monotone' name='Left Hip' dataKey='col18' stroke='blue' dot={false} />
              <Line type='monotone' name='Right Hip' dataKey='col19' stroke='green' dot={false} />
            </LineGraph>
          </IonCol>
        </IonRow>
      </IonGrid>
    </>
  );
};

export const KneeGraphs = ({ data }: KinematicsGraphsProps) => {
  return (
    <>
      <IonGrid class='ion-no-padding'>
        <IonRow>
          <IonCol size='4'>
            <IonCardTitle className='graphTitle'>Sagittal</IonCardTitle>
            <IonCardSubtitle className='graphSubtitle'>Extension (-) / Flexion (+)</IonCardSubtitle>
          </IonCol>
          <IonCol size='4'>
            <IonCardTitle className='graphTitle'>Frontal</IonCardTitle>
            <IonCardSubtitle className='graphSubtitle'>Valgus (-) / Varus (+)</IonCardSubtitle>
          </IonCol>
          <IonCol size='4'>
            <IonCardTitle className='graphTitle'>Transversal</IonCardTitle>
            <IonCardSubtitle className='graphSubtitle'>External Rotation (-) / Internal Rotation (+)</IonCardSubtitle>
          </IonCol>
        </IonRow>

        <IonRow>
          <IonCol class='ion-no-padding' size='4'>
            <LineGraph
              data={data}
              dataKey='kinematicData'
              syncId='kinematics'
              yLabel='Angle (degrees)'
              xLabel='Percentage of stride time'
              width={'99%'}
              height={200}>
              <Line type='monotone' name='Left Knee' dataKey='col3' stroke='blue' dot={false} />
              <Line type='monotone' name='Right Knee' dataKey='col4' stroke='green' dot={false} />
            </LineGraph>
          </IonCol>

          <IonCol class='ion-no-padding' size='4'>
            <LineGraph
              data={data}
              dataKey='kinematicData'
              syncId='kinematics'
              yLabel='Angle (degrees)'
              xLabel='Percentage of stride time'
              width={'99%'}
              height={200}>
              <Line type='monotone' name='Left Knee' dataKey='col12' stroke='blue' dot={false} />
              <Line type='monotone' name='Right Knee' dataKey='col13' stroke='green' dot={false} />
            </LineGraph>
          </IonCol>

          <IonCol class='ion-no-padding' size='4'>
            <LineGraph
              data={data}
              dataKey='kinematicData'
              syncId='kinematics'
              yLabel='Angle (degrees)'
              xLabel='Percentage of stride time'
              width={'99%'}
              height={200}>
              <Line type='monotone' name='Left Knee' dataKey='col20' stroke='blue' dot={false} />
              <Line type='monotone' name='Right Knee' dataKey='col21' stroke='green' dot={false} />
            </LineGraph>
          </IonCol>
        </IonRow>
      </IonGrid>
    </>
  );
};
export const AnkleGraphs = ({ data }: KinematicsGraphsProps) => {
  return (
    <>
      <IonGrid class='ion-no-padding'>
        <IonRow>
          <IonCol size='4'>
            <IonCardTitle className='graphTitle'>Sagittal</IonCardTitle>
            <IonCardSubtitle className='graphSubtitle'>Plantarflexion (-) / Dorsalflexion (+)</IonCardSubtitle>
          </IonCol>
          <IonCol size='4'>
            <IonCardTitle className='graphTitle'>Frontal</IonCardTitle>
            <IonCardSubtitle className='graphSubtitle'>Eversion (-) / Inversion (+)</IonCardSubtitle>
          </IonCol>
          <IonCol size='4'>
            <IonCardTitle className='graphTitle'>Transversal</IonCardTitle>
            <IonCardSubtitle className='graphSubtitle'>External Rotation(-) / Internal Rotation (+)</IonCardSubtitle>
          </IonCol>
        </IonRow>

        <IonRow>
          <IonCol class='ion-no-padding' size='4'>
            <LineGraph
              data={data}
              dataKey='kinematicData'
              syncId='kinematics'
              yLabel='Angle (degrees)'
              xLabel='Percentage of stride time'
              width={'99%'}
              height={200}>
              <Line type='monotone' name='Left Ankle' dataKey='col5' stroke='blue' dot={false} />
              <Line type='monotone' name='Right Ankle' dataKey='col6' stroke='green' dot={false} />
            </LineGraph>
          </IonCol>

          <IonCol class='ion-no-padding' size='4'>
            <LineGraph
              data={data}
              dataKey='kinematicData'
              syncId='kinematics'
              yLabel='Angle (degrees)'
              xLabel='Percentage of stride time'
              width={'99%'}
              height={200}>
              <Line type='monotone' name='Left Ankle' dataKey='col14' stroke='blue' dot={false} />
              <Line type='monotone' name='Right Ankle' dataKey='col15' stroke='green' dot={false} />
            </LineGraph>
          </IonCol>

          <IonCol class='ion-no-padding' size='4'>
            <LineGraph
              data={data}
              dataKey='kinematicData'
              syncId='kinematics'
              yLabel='Angle (degrees)'
              xLabel='Percentage of stride time'
              width={'99%'}
              height={200}>
              <Line type='monotone' name='Left Ankle' dataKey='col22' stroke='blue' dot={false} />
              <Line type='monotone' name='Right Ankle' dataKey='col23' stroke='green' dot={false} />
            </LineGraph>
          </IonCol>
        </IonRow>
      </IonGrid>
    </>
  );
};
