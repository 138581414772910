/** Components to render the kinematics ROM and SpatioTemporal tables */
import * as React from 'react';
import { toMatrix, transpose } from '../../../../hooks/useReports';
import { ReportData } from '../../../../model';
import './index.css';

type TableProps = { data: ReportData };

/** Table of kinematics ROM values used in Walk/Run kinematics report
 * @param data - The Walk or Run report data
 */
export const RomTable = ({ data }: TableProps) => {
  const tableData: number[][] = transpose(data.rom);

  return (
    <div className='table-wrapper'>
      <table>
        <thead>
          <tr>
            <th>Joints</th>
            <th>Left</th>
            <th>Right</th>
            <th>Symmetry</th>
          </tr>
        </thead>
        <tbody>
          <tr className='table__row_border'>
            <th> Pelvis (ROM)</th>
            <td>{tableData[0][0].toFixed(2) + '°'}</td>
            <td>{tableData[0][1].toFixed(2) + '°'}</td>
            <td>{tableData[0][2].toFixed(0) + '%'}</td>
          </tr>
          <tr>
            <th> Hip (ROM)</th>
            <td>{tableData[1][0].toFixed(2) + '°'}</td>
            <td>{tableData[1][1].toFixed(2) + '°'}</td>
            <td>{tableData[1][2].toFixed(0) + '%'}</td>
          </tr>
          <tr>
            <th className='table__row_indent'>Flexion (Y &gt; 0)</th>
            <td>{tableData[2][0].toFixed(2) + '°'}</td>
            <td>{tableData[2][1].toFixed(2) + '°'}</td>
            <td></td>
          </tr>
          <tr className='table__row_border'>
            <th className='table__row_indent'>Extension (Y &lt; 0)</th>
            <td>{tableData[3][0].toFixed(2) + '°'}</td>
            <td>{tableData[3][1].toFixed(2) + '°'}</td>
            <td></td>
          </tr>
          <tr>
            <th> Knee (ROM)</th>
            <td>{tableData[4][0].toFixed(2) + '°'}</td>
            <td>{tableData[4][1].toFixed(2) + '°'}</td>
            <td>{tableData[4][2].toFixed(0) + '%'}</td>
          </tr>
          <tr>
            <th className='table__row_indent'>Stance flexion</th>
            <td>{tableData[5][0].toFixed(2) + '°'}</td>
            <td>{tableData[5][1].toFixed(2) + '°'}</td>
            <td></td>
          </tr>
          <tr className='table__row_border'>
            <th className='table__row_indent'>Varus / Valgus</th>
            <td>{tableData[6][0].toFixed(2) + '°'}</td>
            <td>{tableData[6][1].toFixed(2) + '°'}</td>
            <td></td>
          </tr>
          <tr>
            <th> Ankle (ROM)</th>
            <td>{tableData[7][0].toFixed(2) + '°'}</td>
            <td>{tableData[7][1].toFixed(2) + '°'}</td>
            <td>{tableData[7][2].toFixed(0) + '%'}</td>
          </tr>
          <tr>
            <th className='table__row_indent'>Dorsal flexion</th>
            <td>{tableData[8][0].toFixed(2) + '°'}</td>
            <td>{tableData[8][1].toFixed(2) + '°'}</td>
            <td></td>
          </tr>
          <tr className='table__row_border'>
            <th className='table__row_indent'>Plantar flexion</th>
            <td>{tableData[9][0].toFixed(2) + '°'}</td>
            <td>{tableData[9][1].toFixed(2) + '°'}</td>
            <td></td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

/** Spatio temporal data table used in the Walk kinematics report
 * @param data - The Walk or Run report data
 */
export const SpatioTemporalTable = ({ data }: TableProps) => {
  const tableData: number[][] = toMatrix(data.spatioTemporal);

  return (
    <div className='table-wrapper'>
      <table>
        <thead>
          <tr>
            <th>Temporal parameters</th>
            <th>Left</th>
            <th>Right</th>
          </tr>
        </thead>
        <tbody className='table_border'>
          <tr>
            <th>Stance phase</th>
            <td>{tableData[3][1].toFixed(0) + ' %'}</td>
            <td>{tableData[4][1].toFixed(0) + ' %'}</td>
          </tr>
          <tr>
            <th>Stance phase</th>
            <td>{tableData[0][1].toFixed(2) + ' s'}</td>
            <td>{tableData[1][1].toFixed(2) + ' s'}</td>
          </tr>
          <tr>
            <th>Swing phase</th>
            <td>{tableData[3][2].toFixed(0) + ' %'}</td>
            <td>{tableData[4][2].toFixed(0) + ' %'}</td>
          </tr>
          <tr>
            <th>Swing phase</th>
            <td>{tableData[0][2].toFixed(2) + ' s'}</td>
            <td>{tableData[1][2].toFixed(2) + ' s'}</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

/** Spatio temporal data table used in the Run kinematics report
 * @param data - The Walk or Run report data
 */
export const SpatioTemporalTableRun = ({ data }: TableProps) => {
  const tableData: number[][] = toMatrix(data.spatioTemporal);

  return (
    <div className='table-wrapper'>
      <table>
        <thead>
          <tr>
            <th>Temporal parameters</th>
            <th>Left</th>
            <th>Right</th>
          </tr>
        </thead>
        <tbody className='table_border'>
          <tr>
            <th>Stride duration</th>
            <td>{tableData[0][0].toFixed(2) + ' s'}</td>
            <td>{tableData[1][0].toFixed(2) + ' s'}</td>
          </tr>
          <tr>
            <th>Stance phase</th>
            <td>{tableData[3][1].toFixed(0) + ' %'}</td>
            <td>{tableData[4][1].toFixed(0) + ' %'}</td>
          </tr>
          <tr>
            <th>Stance phase</th>
            <td>{tableData[0][1].toFixed(2) + ' s'}</td>
            <td>{tableData[1][1].toFixed(2) + ' s'}</td>
          </tr>
          <tr>
            <th>Swing phase</th>
            <td>{tableData[3][2].toFixed(0) + ' %'}</td>
            <td>{tableData[4][2].toFixed(0) + ' %'}</td>
          </tr>
          <tr>
            <th>Swing phase</th>
            <td>{tableData[0][2].toFixed(2) + ' s'}</td>
            <td>{tableData[0][2].toFixed(2) + ' s'}</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};
