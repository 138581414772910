/** Component for the 2 sagittal plane graphs plus top-level x-axis */
import * as React from 'react';
import { Legend, Line } from 'recharts';
import { RunReportData } from '../../../model';
import { LineGraph } from '../components/Graph';

type SagittalPlaneGraphProps = {
  data: RunReportData;
};

/** Sagittal plane graph component, showing line graphs for knees & ankles
 * @param data - Graph data rows of {x, y} values
 */
export const SagittalPlaneGraph = ({ data }: SagittalPlaneGraphProps) => {
  return (
    <>
      <LineGraph
        data={data}
        dataKey='respectTheSagittalPlaneData'
        syncId='sagittalPlane'
        yLabel='Knees'
        xLabel='Percentage of stride time'
        height={300}>
        <Line type='monotone' name='Left Knee' dataKey='col0' stroke='blue' dot={false} />
        <Line type='monotone' name='Right Knee' dataKey='col1' stroke='green' dot={false} />
      </LineGraph>
      <LineGraph
        data={data}
        dataKey='respectTheSagittalPlaneData'
        syncId='sagittalPlane'
        yLabel='Ankles'
        xLabel='Percentage of stride time'
        height={300}>
        <Line type='monotone' name='Left Ankle' dataKey='col2' stroke='blue' dot={false} />
        <Line type='monotone' name='Right Ankle' dataKey='col3' stroke='green' dot={false} />
        <Legend
          height={40}
          wrapperStyle={{ bottom: 0, right: 0, width: 'auto' }}
          verticalAlign='bottom'
          iconType='line'
          formatter={(_value, _entry, index) => (index ? 'Right' : 'Left')}
        />
      </LineGraph>
    </>
  );
};
