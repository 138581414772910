import { ReportInfo } from './reportInfo.model';

export type ReportId = string;

// Types for the report as they come out of Matlab
export enum ReportType {
  WALK = 1,
  RUN = 2,
  ERROR = 999,
  KNEEL = 998,
  KNEER = 997,
}
export type Rom = {
  [key: string]: number[];
  col0: number[]; // Left
  col1: number[]; // Right
  col2: number[]; // Symmetry
};
export type SpatioTemporal = {
  [key: string]: number[];
  col0: number[]; // Left (s)
  col1: number[]; // Right (s)
  col2: number[]; // Symmetry (%)
  col3: number[]; // Left (%)
  col4: number[]; // Right (%)
};
export type MobilityStatus = {
  [key: string]: number[];
  col0: number[]; // Left
  col1: number[]; // Right
};
export type KeyPerformanceIndicators = {
  [key: string]: number[];
  col0: number[]; // Left
  col1: number[]; // Right
};
export type KinematicData = {
  [key: string]: number[];
  col0: number[]; // Left Pelvis (Sagittal)
  col1: number[]; // Right Pelvis (Sagittal)
  col2: number[]; // Left Hip (Sagittal)
  col3: number[]; // Right Hip (Sagittal)
  col4: number[]; // Left Knee (Sagittal)
  col5: number[]; // Right Knee (Sagittal)
  col6: number[]; // Left Ankle (Sagittal)
  col7: number[]; // Right Ankle (Sagittal)
  col8: number[]; // Left Pelvis (Coronal)
  col9: number[]; // Right Pelvis (Coronal)
  col10: number[]; // Left Hip (Coronal)
  col11: number[]; // Right Hip (Coronal)
  col12: number[]; // Left Knee (Coronal)
  col13: number[]; // Right Knee (Coronal)
  col14: number[]; // Left Ankle (Coronal)
  col15: number[]; // Right Ankle (Coronal)
  col16: number[]; // Left Pelvis (Transveral)
  col17: number[]; // Right Pelvis (Transveral)
  col18: number[]; // Left Hip (Transveral)
  col19: number[]; // Right Hip (Transveral)
  col20: number[]; // Left Knee (Transveral)
  col21: number[]; // Right Knee (Transveral)
  col22: number[]; // Left Ankle (Transveral)
  col23: number[]; // Right Ankle (Transveral)
};
export type PointsOfInterests = {
  [key: string]: number[];
  col0: number[]; // Left
  col1: number[]; // Right
};
export type Attractors = {
  [key: string]: number[];
  col0: number[]; // Left
  col1: number[]; // Right
};
export type RearPendulumData = {
  [key: string]: number[];
  col0: number[]; // Left Ankle X
  col1: number[]; // Left Ankle Y
  col2: number[]; // Right Ankle X
  col3: number[]; // Right Ankle Y
};
export type RearPendulumPIs = {
  [key: string]: number[];
  col0: number[]; // Left
  col1: number[]; // Right
};
export type RespectTheSagittalPlaneData = {
  [key: string]: number[];
  col0: number[]; // Left Knee Coronal
  col1: number[]; // Right Knee Coronal
  col2: number[]; // Left Ankle Coronal
  col3: number[]; // Right Ankle Coronal
};
export type AreasOfConcern = {
  [key: string]: number[];
  col0: number[]; // Left
  col1: number[]; // Right
};

/**  Walk report data type */
export type WalkReportData = {
  [key: string]: any;
  reportType: ReportType;
  rom: Rom;
  mobilityStatus: MobilityStatus;
  spatioTemporal: SpatioTemporal;
  keyPerformanceIndicators: KeyPerformanceIndicators;
  kinematicData: KinematicData;
  pointsOfInterests: PointsOfInterests;
  areasOfConcern: AreasOfConcern;
};

/** RunReport data type */
export type RunReportData = {
  [key: string]: any;
  reportType: ReportType;
  attractors: Attractors;
  rom: Rom;
  spatioTemporal: SpatioTemporal;
  kinematicData: KinematicData;
  pointsOfInterests: PointsOfInterests;
  rearPendulumData: RearPendulumData;
  rearPendulumPIs: RearPendulumPIs;
  respectTheSagittalPlaneData: RespectTheSagittalPlaneData;
  areasOfConcern: AreasOfConcern;
};

/** Report data type, cast this to WalkReportData or RunReportData depending on report type */
export type ReportData = WalkReportData | RunReportData;

/** Report type, merging ReportInfo with the report data for easy access */
export type Report = ReportInfo & {
  data: ReportData | undefined;
};

export type ReportDict = {
  [id: string]: Report;
};
