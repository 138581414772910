/** Walk analysis report tab */
import {
  IonCol,
  IonContent,
  IonGrid,
  IonImg,
  IonPage,
  IonRow,
  IonText,
  IonItem,
  IonIcon,
  IonChip,
  IonButton,
  IonButtons,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
  IonModal,
} from '@ionic/react';
import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import ankle from '../../../assets/avatars/walk/analysis/Ankle.png';
import hip from '../../../assets/avatars/walk/analysis/Hip.png';
import hipExtensionAtTerminalStance from '../../../assets/avatars/walk/analysis/HipExtensionAtTerminalStance.png';
import hipExtensionAtTerminalSwing from '../../../assets/avatars/walk/analysis/HipExtensionAtTerminalSwing.png';
import knee from '../../../assets/avatars/walk/analysis/Knee.png';
import kneeFlexionAtMidstance from '../../../assets/avatars/walk/analysis/KneeFlexionAtMidstance.png';
import kneeFlexionAtMidswing from '../../../assets/avatars/walk/analysis/KneeFlexionAtMidswing.png';
import kneeExtensionAtTerminalStance from '../../../assets/avatars/walk/analysis/KneeExtensionAtTerminalStance.png';
import kneeStabilityAtLoadingResponse from '../../../assets/avatars/walk/analysis/KneeStabilityAtLoadingResponse.png';
import pelvicObliquity from '../../../assets/avatars/walk/analysis/PelvicObliquity.png';
import pelvis from '../../../assets/avatars/walk/analysis/Pelvis.png';
import varusValgus from '../../../assets/avatars/walk/analysis/VarusValgus.png';
import { transpose, toMatrix } from '../../../hooks/useReports';
import { Report, WalkReportData } from '../../../model';
import { NameField, StatusDotField } from '../components/Fields';
import { ReportSection } from '../components/ReportSection';
import './WalkAnalysisReportTab.css';
import {
  ModalHipExtensionTerminalStance,
  ModalKneeFlexionMidstance,
  ModalKneeFlexionMidswing,
  ModalKneeStabilityLoadingResponse,
  ModalPelvicObliquity,
  ModalVarusValgus,
  ModalMobilityStatus,
} from '../components/ModalsWalk';
import { informationCircleOutline, pricetagsOutline } from 'ionicons/icons';
import { ModalHipExtensionTerminalSwing } from '../components/ModalsWalk/ModalHipExtensionTerminalSwing';
import { ModalKneeExtensionTerminalStance } from '../components/ModalsWalk/ModalKneeExtensionTerminalStance';
import { useState } from 'react';

type MobilityProps = {
  title: string;
  img: string;
  romRow: number[];
  statusRow: number[];
};

/** Mobility report card content, showing avatar, left/right status and symmetry fields
 * @param img - The avatar
 * @param romRow - list of ROM data values
 * @param statusRow - list of status field tuples for (Left, Right)
 */
const Mobility = ({ title, img, romRow, statusRow }: MobilityProps) => {
  return (
    <IonGrid>
      <IonRow>
        <IonCol>
          <IonImg src={img} className='avatar' />
          <IonText className='mobility__title'>{title}</IonText>
        </IonCol>
      </IonRow>
      <IonRow>
        <IonCol>
          <StatusDotField name='Left' value={romRow[0].toFixed(0) + '°'} status={statusRow[0]} />
        </IonCol>
        <IonCol>
          <StatusDotField name='Right' value={romRow[1].toFixed(0) + '°'} status={statusRow[1]} />
        </IonCol>
      </IonRow>
    </IonGrid>
  );
};

type CardProps = {
  title: string;
  subtitle?: string;
  info: React.ReactNode;
  img: string;
  imgCss?: string;
  cssClass?: string;
};

const kpiLabels = [
  ['OK', 'Moderate', 'Poor'],
  ['Positive', 'OK', 'Drop'],
];

type KpiCardProps = CardProps & {
  kpiRow: number[];
  labels: string[];
  id: string;
};

/** Report card for kpi data
 * @param img - The avatar
 * @param kpiRow - Tuple of (Left, Right) kpi values
 * @param labels - rows of kpi labels, row 0 is Left, row 1 is Right
 */
const KpiCard = ({ title, subtitle, info, img, imgCss, kpiRow, labels }: KpiCardProps) => {
  const [showModal, setShowModal] = useState(false);
  const error = kpiRow[0] === 3 || kpiRow[1] === 3 ? 'error' : '';
  const cardClassName = error ? 'sectionerror' : 'section';
  const kpiLabel = (labels: string[], kpi: number) => {
    return labels[kpi - 1];
  };

  return (
    <>
      <IonModal isOpen={showModal} cssClass='section__modal' onDidDismiss={() => setShowModal(false)}>
        {info}
        <IonButton onClick={() => setShowModal(false)}>Close</IonButton>
      </IonModal>

      <IonCard className={cardClassName}>
        <IonCardHeader className='section-header'>
          <div>
            <IonCardTitle className='cardTitle'>{title}</IonCardTitle>
            {subtitle && <IonCardSubtitle className='cardSubtitle'>{subtitle}</IonCardSubtitle>}
          </div>
          <IonButtons className='section-header__buttons'>
            <IonButton slot='icon-only' className='section-header__info' onClick={() => setShowModal(true)}>
              <IonIcon icon={informationCircleOutline} />
            </IonButton>
          </IonButtons>
        </IonCardHeader>
        <IonCardContent className='section-content'>
          <IonGrid class='ion-no-padding'>
            <IonRow>
              <IonCol>
                <IonImg src={img} className={imgCss} />
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol>
                <IonGrid>
                  <IonRow align-items-center>
                    <IonCol size='6'>
                      <NameField name='Left' value={kpiLabel(labels, kpiRow[0])} />
                    </IonCol>
                    <IonCol size='6'>
                      <NameField name='Right' value={kpiLabel(labels, kpiRow[1])} />
                    </IonCol>
                  </IonRow>
                </IonGrid>
              </IonCol>
            </IonRow>
          </IonGrid>
        </IonCardContent>
      </IonCard>
    </>
  );
};

type RomCardProps = CardProps & {
  romRow: number[];
  id: string;
};

/** Report card for ROM data
 * @param img - The avatar
 * @param romRow - list of ROM values for Left, Right, Symmetry
 */
const RomCard = ({ title, subtitle, info, img, imgCss, romRow }: RomCardProps) => {
  const [showModal, setShowModal] = useState(false);
  const error = romRow[0] > 10 || romRow[1] > 10 || romRow[0] < -10 || romRow[1] < -10 ? 'error' : '';
  const cardClassName = error ? 'sectionerror' : 'section';

  return (
    <>
      <IonModal isOpen={showModal} cssClass='section__modal' onDidDismiss={() => setShowModal(false)}>
        {info}
        <IonButton onClick={() => setShowModal(false)}>Close</IonButton>
      </IonModal>

      <IonCard className={cardClassName}>
        <IonCardHeader className='section-header'>
          <div>
            <IonCardTitle className='cardTitle'>{title}</IonCardTitle>
            {subtitle && <IonCardSubtitle className='cardSubtitle'>{subtitle}</IonCardSubtitle>}
          </div>
          <IonButtons className='section-header__buttons'>
            <IonButton slot='icon-only' className='section-header__info' onClick={() => setShowModal(true)}>
              <IonIcon icon={informationCircleOutline} />
            </IonButton>
          </IonButtons>
        </IonCardHeader>
        <IonCardContent className='section-content'>
          <IonGrid class='ion-no-padding'>
            <IonRow>
              <IonCol>
                <IonImg src={img} className={imgCss} />
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol>
                <IonGrid>
                  <IonRow>
                    <IonCol>
                      <NameField name='Left' value={romRow[0].toFixed(0) + '°'} />
                    </IonCol>
                    <IonCol>
                      <NameField name='Right' value={romRow[1].toFixed(0) + '°'} />
                    </IonCol>
                  </IonRow>
                </IonGrid>
              </IonCol>
            </IonRow>
          </IonGrid>
        </IonCardContent>
      </IonCard>
    </>
  );
};

type WalkReportProps = RouteComponentProps & {
  report: Report;
};

/** The Walk analysis report tab
 * @param report - The full walk report info and data
 */
export const WalkAnalysisReportTab = ({ report }: WalkReportProps) => {
  const data = report.data as WalkReportData | undefined;

  if (!data) {
    return <IonText>Data not found.</IonText>;
  }

  // TODO: useEffect hook to avoid recalculating
  const romData = transpose(data.rom);
  const kpiData = transpose(data.keyPerformanceIndicators);
  const statusData = transpose(data.mobilityStatus);
  const tableData: number[][] = toMatrix(data.spatioTemporal);
  const speedLabel = () => {
    if (tableData[0][4] === 1) {
      return 'Ok';
    } else if (tableData[0][4] === 2) {
      document.getElementById('showSpeedLabel')?.classList.add('warning');
      return 'Slow';
    } else if (tableData[0][4] === 3) {
      document.getElementById('showSpeedLabel')?.classList.add('danger');
      return 'Too slow';
    } else {
      document.getElementById('showSpeedLabel')?.classList.add('danger');
      return 'Too fast';
    }
  };

  return (
    <IonPage>
      <IonContent className='ion-padding'>
        {report.tags.length > 0 && (
          <IonItem>
            <IonIcon icon={pricetagsOutline} slot='start' />
            {report.tags.map((tag) => (
              <IonChip key={tag}>{tag}</IonChip>
            ))}
          </IonItem>
        )}

        {data && (
          <IonGrid>
            <IonRow>
              <IonCol>
                <ReportSection title='Mobility Status' info={<ModalMobilityStatus />}>
                  <IonGrid>
                    <IonRow>
                      <IonCol size='12'>
                        <IonItem>
                          Stride Duration: {tableData[0][0].toFixed(2)}s.
                          <span className='SpeedLabel' id='showSpeedLabel'>
                            {' '}
                            {speedLabel()}{' '}
                          </span>
                        </IonItem>
                      </IonCol>
                    </IonRow>

                    <IonRow>
                      <IonCol size='3'>
                        <Mobility title='Pelvis' img={pelvis} romRow={romData[0]} statusRow={statusData[0]} />
                      </IonCol>
                      <IonCol size='3'>
                        <Mobility title='Hips' img={hip} romRow={romData[1]} statusRow={statusData[1]} />
                      </IonCol>
                      <IonCol size='3'>
                        <Mobility title='Knees' img={knee} romRow={romData[4]} statusRow={statusData[2]} />
                      </IonCol>
                      <IonCol size='3'>
                        <Mobility title='Ankles' img={ankle} romRow={romData[7]} statusRow={statusData[3]} />
                      </IonCol>
                    </IonRow>
                  </IonGrid>
                </ReportSection>
              </IonCol>
            </IonRow>

            <IonRow>
              <IonCol size='3'>
                <KpiCard
                  id='Attractorcard'
                  title='Hip extension'
                  subtitle='Terminal stance'
                  img={hipExtensionAtTerminalStance}
                  labels={kpiLabels[0]}
                  kpiRow={kpiData[1]}
                  info={<ModalHipExtensionTerminalStance />}
                />
              </IonCol>
              <IonCol size='3'>
                <KpiCard
                  id='Attractorcard'
                  title='Knee extension'
                  subtitle='Terminal stance'
                  img={kneeExtensionAtTerminalStance}
                  labels={kpiLabels[0]}
                  kpiRow={kpiData[0]}
                  info={<ModalKneeExtensionTerminalStance />}
                />
              </IonCol>
              <IonCol size='3'>
                <KpiCard
                  id='Attractorcard'
                  title='Knee flexion'
                  subtitle='Midswing'
                  img={kneeFlexionAtMidswing}
                  labels={kpiLabels[0]}
                  kpiRow={kpiData[6]}
                  info={<ModalKneeFlexionMidswing />}
                />
              </IonCol>
              <IonCol size='3'>
                <KpiCard
                  id='Attractorcard'
                  title='Hip extension'
                  subtitle='Terminal swing'
                  img={hipExtensionAtTerminalSwing}
                  labels={kpiLabels[0]}
                  kpiRow={kpiData[2]}
                  info={<ModalHipExtensionTerminalSwing />}
                />
              </IonCol>
            </IonRow>

            <IonRow>
              <IonCol size='3'>
                <KpiCard
                  id='Attractorcard'
                  title='Knee stability'
                  subtitle='Loading response'
                  img={kneeStabilityAtLoadingResponse}
                  labels={kpiLabels[0]}
                  kpiRow={kpiData[3]}
                  info={<ModalKneeStabilityLoadingResponse />}
                />
              </IonCol>
              <IonCol size='3'>
                <KpiCard
                  id='Attractorcard'
                  title='Knee flexion'
                  subtitle='Midstance'
                  img={kneeFlexionAtMidstance}
                  labels={kpiLabels[0]}
                  kpiRow={kpiData[5]}
                  info={<ModalKneeFlexionMidstance />}
                />
              </IonCol>
              <IonCol size='3'>
                <RomCard
                  id='Attractorcard'
                  title='Varus / Valgus'
                  subtitle=' '
                  img={varusValgus}
                  romRow={romData[6]}
                  info={<ModalVarusValgus />}
                />
              </IonCol>
              <IonCol size='3'>
                <KpiCard
                  id='Attractorcard'
                  title='Pelvic obliquity'
                  img={pelvicObliquity}
                  labels={kpiLabels[1]}
                  kpiRow={kpiData[4]}
                  info={<ModalPelvicObliquity />}
                />
              </IonCol>
            </IonRow>
          </IonGrid>
        )}
      </IonContent>
    </IonPage>
  );
};
