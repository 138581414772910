import React from 'react';

import { View, StyleSheet, Text, Image } from '@react-pdf/renderer';
import { AttractorField } from './PDFFields';
import { Attractors, Report, RunReportData } from '../../../../../model';
import ankleStiffness from '../../../../../assets/avatars/run/analysis/AnkleStiffness.png';
import kneeExtensionPreswing from '../../../../../assets/avatars/run/analysis/KneeExtensionPreswing.png';
import hipLock from '../../../../../assets/avatars/run/analysis/HipLock.png';
import positiveRunningToeOff from '../../../../../assets/avatars/run/analysis/PositiveRunningToeOff.png';
import swingLegRecovery from '../../../../../assets/avatars/run/analysis/SwingLegRecovery.png';
import kneeAngleMidswing from '../../../../../assets/avatars/run/analysis/KneeAngleMidswing.png';
import swingLegRetraction from '../../../../../assets/avatars/run/analysis/SwingLegRetraction.png';
import scissors from '../../../../../assets/avatars/run/analysis/Scissors.png';
import footPlant from '../../../../../assets/avatars/run/analysis/FootPlant.png';
import projectionFootplant from '../../../../../assets/avatars/run/analysis/ProjectionFootplant.png';
import kneeFlexionMidstance from '../../../../../assets/avatars/run/analysis/kneeFlexionMidstance.png';
import positiveRunningMidstance from '../../../../../assets/avatars/run/analysis/PositiveRunningMidstance.png';

const styles = StyleSheet.create({
  KpiCardscontainer: {
    flexDirection: 'row',
    flexWrap: 'nowrap',
    justifyContent: 'space-between',
    width: '90%',
    marginHorizontal: '24px',
    alignSelf: 'center',
    marginBottom: '10px',
    paddingVertical: '10px',
  },
  Title: {
    fontSize: 10,
    fontWeight: 'bold',
    fontFamily: 'Montserrat',
  },
  Subtitle: {
    fontSize: 6,
    fontFamily: 'Montserrat',
    paddingBottom: 10,
  },
  Labelname: {
    fontSize: 10,
    fontFamily: 'Montserrat',
    alignSelf: 'center',
  },
  Labeloutput: {
    fontsize: 8,
    fontFamily: 'Montserrat',
    color: '#2066FA',
    alignSelf: 'center',
  },
  CardContainer: {
    display: 'flex',
    paddingHorizontal: 5,
    paddingVertical: 10,
    flexDirection: 'column',
    flexWrap: 'nowrap',
    flex: '0 0 132px',
    alignContent: 'center',
    border: 1,
    borderColor: '#E2E2E2',
    borderRadius: 4,
    borderStyle: 'solid',
  },
  avatar: {
    width: '100px',
    alignSelf: 'center',
  },
  CardOutputContainer: {
    flexDirection: 'row',
    flexWrap: 'nowrap',
    alignSelf: 'center',
    justifyContent: 'space-between',
  },
});

export type AttractorLabels = string[][];
export function toAttractorLabels(data: Attractors): AttractorLabels {
  const labels = [
    ['Positive', 'Negative'], // Positive Running - Toe off (0)
    ['Sufficient', 'Absent'], // Ankle Stiffness (1)
    ['Reactive', 'No extens.', 'Hyperext.'], //Knee Extension - preswing (2)
    ['Lock', 'Ok', 'Drop'], // Hip Lock (3)
    ['Straight', 'Round'], // Rear Pendulum (4)
    ['Fierce', 'Slow'], // Swingleg Recovery (5)
    ['OK', 'Obtuse'], // Knee angle - Midswing (6)
    ['Positive', 'Negative'], // Positive Running - Midstance (7)
    ['OK', 'Poor'], // Projection Footplant (8)
    ['Active', 'Passive'], // Swingleg Retraction (9)
    ['OK', 'Fair', 'Late'], // Scissors (10)
    ['OK', 'Poor'], // Footplant from above (11)
    ['Ok', 'No'], // Respect the sagittal plane - Ankle (12)
    ['Ok', 'No'], // Respect the sagittal plane - Knee (13)
    ['Ok', 'Collapse'], //Knee flexion at Midstance (14)
  ];
  return data.col0.map((_value: number, rowIdx: number) => {
    return Object.keys(data)
      .sort()
      .map((colId: string) => {
        const labelIdx = data[colId][rowIdx] - 1;
        return labels[rowIdx][labelIdx];
      });
  });
}

type AttractorCardProps = {
  title: string;
  subtitle?: string;
  img: string;
  labels: string[];
};

/** Report card for kpi data
 * @param img - The avatar
 * @param kpiRow - Tuple of (Left, Right) kpi values
 * @param labels - rows of kpi labels, row 0 is Left, row 1 is Right
 */
const AttractorCard = ({ title, subtitle, img, labels }: AttractorCardProps) => (
  <View style={styles.CardContainer} debug={false}>
    <Text style={styles.Title}>{title}</Text>
    <Text style={styles.Subtitle}>{subtitle}</Text>
    <Image style={styles.avatar} src={img} />
    <View style={styles.CardOutputContainer}>
      <AttractorField name={'Left'} value={labels[0]} />
      <AttractorField name={'Right'} value={labels[1]} />
    </View>
  </View>
);

type RunReportProps = {
  report?: Report;
};

export const RunAnalysisCards = ({ report }: RunReportProps) => {
  const data = report?.data as RunReportData;
  const attractorLabels = toAttractorLabels(data.attractors);

  return (
    <>
      <View style={styles.KpiCardscontainer}>
        <AttractorCard title={'Ankle stiffness'} subtitle={' '} img={ankleStiffness} labels={attractorLabels[1]} />
        <AttractorCard
          title={'Knee extension'}
          subtitle={'Pre-swing'}
          img={kneeExtensionPreswing}
          labels={attractorLabels[2]}
        />
        <AttractorCard title={'Hip Lock'} subtitle={' '} img={hipLock} labels={attractorLabels[3]} />
        <AttractorCard
          title={'Positive running'}
          subtitle={'Toe-off'}
          img={positiveRunningToeOff}
          labels={attractorLabels[0]}
        />
      </View>
      <View style={styles.KpiCardscontainer}>
        <AttractorCard title={'Swing leg recovery'} subtitle={' '} img={swingLegRecovery} labels={attractorLabels[5]} />
        <AttractorCard
          title={'Knee flexion'}
          subtitle={'Midswing'}
          img={kneeAngleMidswing}
          labels={attractorLabels[6]}
        />
        <AttractorCard
          title={'Swing leg retraction'}
          subtitle={' '}
          img={swingLegRetraction}
          labels={attractorLabels[9]}
        />
        <AttractorCard title={'Scissors motion'} subtitle={'Toe-off'} img={scissors} labels={attractorLabels[10]} />
      </View>
      <View style={styles.KpiCardscontainer}>
        <AttractorCard title={'Foot plant from above'} subtitle={' '} img={footPlant} labels={attractorLabels[11]} />
        <AttractorCard
          title={'Projection footplant'}
          subtitle={' '}
          img={projectionFootplant}
          labels={attractorLabels[8]}
        />
        <AttractorCard
          title={'Knee flexion'}
          subtitle={'Midstance'}
          img={kneeFlexionMidstance}
          labels={attractorLabels[14]}
        />
        <AttractorCard
          title={'Positive running'}
          subtitle={'Midstance'}
          img={positiveRunningMidstance}
          labels={attractorLabels[7]}
        />
      </View>
    </>
  );
};
