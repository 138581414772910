import firebase from 'firebase/app';

/** Credentials to access Firebase.
 * Including cloud storage, database, and auth.
 */
const firebaseConfig = {
  apiKey: 'AIzaSyCn3m4zUgiyQaXwHbjSfR9MJ21bA8qvjyM',
  projectId: 'exalted-summer-276812',
  databaseURL: 'https://exalted-summer-276812.firebaseio.com',
  authDomain: 'portal.oryxmovementsolutions.com', // "exalted-summer-276812.firebaseapp.com",
  storageBucket: 'exalted-summer-276812.appspot.com',
  messagingSenderId: '482222421307',
};

// Ensure that you do not initialize twice.
if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig);
}
export const myFirebase = firebase;
