import React from 'react';
import { Text, View, StyleSheet, Image } from '@react-pdf/renderer';
import calendaroutline from './Assets/calendar-outline.png';
import bodyoutline from './Assets/body-outline.png';
import warningoutline from './Assets/warning-outline.png';
import pricetagoutline from './Assets/pricetag-outline.png';
import { Report, ReportInfo, ReportType } from './../../../../../model';

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    borderWidth: 1,
    borderColor: '#E2E2E2',
    borderRadius: 4,
    borderStyle: 'solid',
    alignItems: 'stretch',
    width: '500px',
    padding: 20,
    marginHorizontal: 50,
  },
  csvNameRow: {
    flexDirection: 'row',
    borderBottomWidth: 1,
    borderBottomColor: '#E2E2E2',
    padding: 10,
    width: '500px',
    justifyContent: 'space-between',
    alignSelf: 'stretch',
  },
  csvName: {
    fontSize: 14,
    fontWeight: 'bold',
  },
  Text: {
    fontSize: 12,
    fontWeight: 'normal',
    fontFamily: 'Montserrat',
  },
  detailRow: {
    flexDirection: 'row',
    padding: 10,
    width: '500px',
    justifyContent: 'flex-start',
    alignSelf: 'stretch',
    alignItems: 'center',
  },
  iconColumn: {
    flexDirection: 'column',
    width: '20px',
    marginRight: '20px',
  },
});

export interface IReportCardProps {
  reportInfo: ReportInfo;
  report?: Report;
}
export const PDFReportCard = ({ report, reportInfo }: IReportCardProps) => {
  const data = report?.data;
  const activity = data?.reportType === ReportType.RUN ? 'Run' : 'Walk';
  const areasOfConcern = data?.areasOfConcern ? data?.areasOfConcern.col0[0] + data?.areasOfConcern.col1[0] : '';

  return (
    <View style={styles.container}>
      <View style={styles.csvNameRow}>
        <Text style={styles.csvName}>{reportInfo.filename}</Text>
      </View>
      <View style={styles.detailRow}>
        <Image src={calendaroutline} style={styles.iconColumn} />
        <Text style={styles.Text}>{reportInfo.uploadTimestamp.toLocaleString()}</Text>
      </View>
      <View style={styles.detailRow}>
        <Image src={bodyoutline} style={styles.iconColumn} />
        <Text style={styles.Text}>Activity: {activity}</Text>
      </View>
      <View style={styles.detailRow}>
        <Image src={warningoutline} style={styles.iconColumn} />
        <Text style={styles.Text}>{areasOfConcern} areas of concern</Text>
      </View>
      {reportInfo.tags[0] != '' && (
        <View style={styles.detailRow}>
          <Image src={pricetagoutline} style={styles.iconColumn} />
          {reportInfo.tags[0] != '' && <Text style={styles.Text}>{reportInfo.tags[0]} </Text>}
          {reportInfo.tags[1] != '' && <Text style={styles.Text}> - {reportInfo.tags[1]}</Text>}
        </View>
      )}
    </View>
  );
};
