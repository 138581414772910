import React from 'react';

import { View, StyleSheet, Text, Image } from '@react-pdf/renderer';
import { KPIField_One, KPIField_Zero, ROMField } from './PDFFields';
import { Report, WalkReportData } from '../../../../../model';
import { transpose } from '../../../../../hooks/useReports';
import hipExtenstionAtTerminalStance from '../../../../../assets/avatars/walk/analysis/HipExtensionAtTerminalStance.png';
import kneeExtensionAtTerminalStance from '../../../../../assets/avatars/walk/analysis/KneeExtensionAtTerminalStance.png';
import kneeFlexionAtMidswing from '../../../../../assets/avatars/walk/analysis/KneeFlexionAtMidswing.png';
import hipExtensionAtTerminalSwing from '../../../../../assets/avatars/walk/analysis/HipExtensionAtTerminalSwing.png';
import kneeStabilityAtLoadingResponse from '../../../../../assets/avatars/walk/analysis/KneeStabilityAtLoadingResponse.png';
import kneeFlexionAtMidstance from '../../../../../assets/avatars/walk/analysis/KneeFlexionAtMidstance.png';
import varusValgus from '../../../../../assets/avatars/walk/analysis/VarusValgus.png';
import pelvicObliquity from '../../../../../assets/avatars/walk/analysis/PelvicObliquity.png';

const styles = StyleSheet.create({
  KpiCardscontainer: {
    flexDirection: 'row',
    flexWrap: 'nowrap',
    justifyContent: 'space-between',
    width: '90%',
    marginHorizontal: '24px',
    alignSelf: 'center',
    marginBottom: '10px',
    paddingVertical: '10px',
  },
  Title: {
    fontSize: 10,
    fontWeight: 'bold',
    fontFamily: 'Montserrat',
  },
  Subtitle: {
    fontSize: 6,
    fontFamily: 'Montserrat',
    paddingBottom: 10,
  },
  Labelname: {
    fontSize: 10,
    fontFamily: 'Montserrat',
    alignSelf: 'center',
  },
  Labeloutput: {
    fontsize: 8,
    fontFamily: 'Montserrat',
    color: '#2066FA',
    alignSelf: 'center',
  },
  CardContainer: {
    display: 'flex',
    paddingHorizontal: 5,
    paddingVertical: 10,
    flexDirection: 'column',
    flexWrap: 'nowrap',
    flex: '0 0 132px',
    alignContent: 'center',
    border: 1,
    borderColor: '#E2E2E2',
    borderRadius: 4,
    borderStyle: 'solid',
  },
  avatar: {
    width: '70px',
    alignSelf: 'center',
  },
  CardOutputContainer: {
    flexDirection: 'row',
    flexWrap: 'nowrap',
    alignSelf: 'center',
    justifyContent: 'space-between',
  },
});

type KpiCardProps = {
  title: string;
  subtitle?: string;
  img: string;
  kpiRow: number[];
};

/** Report card for kpi data
 * @param img - The avatar
 * @param kpiRow - Tuple of (Left, Right) kpi values
 * @param labels - rows of kpi labels, row 0 is Left, row 1 is Right
 */
const KpiCard = ({ title, subtitle, img, kpiRow }: KpiCardProps) => (
  <View style={styles.CardContainer} debug={false}>
    <Text style={styles.Title}>{title}</Text>
    <Text style={styles.Subtitle}>{subtitle}</Text>
    <Image style={styles.avatar} src={img} />
    <View style={styles.CardOutputContainer}>
      <KPIField_Zero name={'Left'} value={kpiRow[0]} />
      <KPIField_Zero name={'Right'} value={kpiRow[1]} />
    </View>
  </View>
);

const KpiCard_Two = ({ title, subtitle, img, kpiRow }: KpiCardProps) => (
  <View style={styles.CardContainer} debug={false}>
    <Text style={styles.Title}>{title}</Text>
    <Text style={styles.Subtitle}>{subtitle}</Text>
    <Image style={styles.avatar} src={img} />
    <View style={styles.CardOutputContainer}>
      <KPIField_One name={'Left'} value={kpiRow[0]} />
      <KPIField_One name={'Right'} value={kpiRow[1]} />
    </View>
  </View>
);

type RomCardProps = {
  title: string;
  subtitle?: string;
  img: string;
  romRow: number[];
};

/** Report card for kpi data
 * @param img - The avatar
 * @param romRow - Tuple of (Left, Right) kpi values
 * @param labels - rows of kpi labels, row 0 is Left, row 1 is Right
 */
const RomCard = ({ title, subtitle, img, romRow }: RomCardProps) => (
  <View style={styles.CardContainer} debug={false}>
    <Text style={styles.Title}>{title}</Text>
    <Text style={styles.Subtitle}>{subtitle}</Text>
    <Image style={styles.avatar} src={img} />
    <View style={styles.CardOutputContainer}>
      <ROMField name={'Left'} value={romRow[0].toFixed(0) + '°'} />
      <ROMField name={'Right'} value={romRow[1].toFixed(0) + '°'} />
    </View>
  </View>
);

type WalkReportProps = {
  report?: Report;
};

export const WalkAnalysisCards = ({ report }: WalkReportProps) => {
  const data = report?.data as WalkReportData;
  const romData = transpose(data.rom);
  // const tableData: number[][] = toMatrix(data.spatioTemporal);
  const kpiData = transpose(data.keyPerformanceIndicators);

  return (
    <>
      <View style={styles.KpiCardscontainer}>
        <KpiCard
          title={'Hip Extension'}
          subtitle={'Terminal Stance'}
          img={hipExtenstionAtTerminalStance}
          kpiRow={kpiData[1]}
        />
        <KpiCard
          title={'Knee Extension'}
          subtitle={'Terminal Stance'}
          img={kneeExtensionAtTerminalStance}
          kpiRow={kpiData[0]}
        />
        <KpiCard title={'Knee flexion'} subtitle={'Midswing'} img={kneeFlexionAtMidswing} kpiRow={kpiData[6]} />
        <KpiCard
          title={'Hip Extension'}
          subtitle={'Terminal Swing'}
          img={hipExtensionAtTerminalSwing}
          kpiRow={kpiData[2]}
        />
      </View>
      <View style={styles.KpiCardscontainer}>
        <KpiCard
          title={'Knee Stability'}
          subtitle={'Loading Response'}
          img={kneeStabilityAtLoadingResponse}
          kpiRow={kpiData[3]}
        />
        <KpiCard title={'Knee flexion'} subtitle={'Midstance'} img={kneeFlexionAtMidstance} kpiRow={kpiData[5]} />
        <RomCard title={'Varus / Valgus'} subtitle={' '} img={varusValgus} romRow={romData[6]} />
        <KpiCard_Two title={'Pelvic Obiliquity'} subtitle={' '} img={pelvicObliquity} kpiRow={kpiData[4]} />
      </View>
    </>
  );
};
