import React from 'react';
import {
  IonCardHeader,
  IonCardTitle,
  IonGrid,
  IonRow,
  IonCol,
  IonImg,
  IonSlides,
  IonSlide,
  IonIcon,
} from '@ionic/react';
import './modalindex.css';
import IBimageScissors1 from './assets/IBimageScissors1.png';
import IBimageScissors2 from './assets/IBimageScissors2.png';
import { chevronDownOutline, chevronUpOutline } from 'ionicons/icons';

const slideOpts = {
  initialSlide: 0,
  speed: 400,
  direction: 'vertical',
};

export const ModalScissors: React.FC = () => {
  return (
    <>
      <IonCardHeader className='modal_section-header'>
        <div>
          <IonCardTitle>Scissors motion</IonCardTitle>
        </div>
      </IonCardHeader>

      <IonSlides pager={true} options={slideOpts} mode='ios'>
        <IonSlide>
          <IonGrid>
            <IonRow class='ion-align-items-center'>
              <IonCol size='12' size-lg='7' size-xl='7'>
                <div>
                  <IonImg src={IBimageScissors1} class='modalAvatar' />
                </div>
              </IonCol>
              <IonCol>
                <div>
                  Directly after toe off the rear leg’s knee and hip bend, while at the same time the hip and knee of
                  the frontal leg will extend. These movements are reflexively linked to each other and form the overall
                  pattern called <i>scissors</i>: a combination of the stumble reflex (swing-leg recovery) and crossed
                  extensor reflex (swing leg retraction plus subsequent propulsion phase). In other words: flexion of
                  knee and hip from one leg leads to extension of hip and knee of the other and vice versa.
                  <br></br>
                  <br></br>
                  Scissors is qualified as good when swing leg recovery is fast enough when both knees are at least next
                  to each other at midstance.
                </div>
              </IonCol>
            </IonRow>
            <IonRow class='ion-hide-xl-down'>
              <IonCol class='ion-float-right'>
                <IonIcon icon={chevronDownOutline} className='Modalicon' />
              </IonCol>
            </IonRow>
          </IonGrid>
        </IonSlide>

        <IonSlide>
          <IonGrid>
            <IonRow class='ion-hide-xl-down'>
              <IonCol class='ion-float-right'>
                <IonIcon icon={chevronUpOutline} className='Modalicon' />
              </IonCol>
            </IonRow>
            <IonRow class='ion-align-items-center'>
              <IonCol size='12' size-lg='7' size-xl='7'>
                <div>
                  <IonImg src={IBimageScissors2} class='modalAvatar' />
                </div>
              </IonCol>
              <IonCol>
                <div>
                  If the body is forced to respond to peak counteracting forces while the swing leg is still trailing,
                  this should be considered as a severe mistake; shock absorption will be compromised, and much energy
                  released throughout loading response will be lost.
                  <br></br>
                  <br></br>
                  If there is no swing-leg retraction, the leg will be pointing forward at foot plant (also see:
                  projection foot plant at midstance). Once such a foot plant in front of the hip starts to become a
                  habit, there is no longer any need to bring the rear leg rapidly forwards. The rear is only brought
                  forwards rapidly if there is enough tension in the hip flexors. If this is no longer needed, flexors
                  are tensed by keeping the hip somewhat back.
                  <br></br>
                  <br></br>
                  This leads to loss of tension in the whole system, leading to less control, loss of performance and
                  increased risk of injury.
                </div>
              </IonCol>
            </IonRow>
          </IonGrid>
        </IonSlide>
      </IonSlides>
    </>
  );
};
