/** Component to show report metadata in a card */
import {
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardTitle,
  IonChip,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonSpinner,
  IonModal,
  IonToast,
  IonActionSheet,
  IonButton,
} from '@ionic/react';
import { PDFDownloadLink } from '@react-pdf/renderer';
import {
  alertOutline,
  bodyOutline,
  calendarOutline,
  closeOutline,
  cloudDownloadOutline,
  menuOutline,
  pricetagsOutline,
  trashOutline,
  warningOutline,
} from 'ionicons/icons';
import * as React from 'react';
import { useState } from 'react';
import { useHistory } from 'react-router';
import { useReportContext } from '../../components/reportContext';
import { Report, ReportInfo, ReportStatus, ReportType } from '../../model';
import { TestDocument } from '../Report/components/PDF/TestDocument';
import './ReportCard.css';

export interface IReportCardProps {
  reportInfo: ReportInfo;
  report?: Report;
}

/** Component to show report metadata in a card
 * @param report - Shows a spinner if the reportInfo status is "pending", hence still processing in the cloud
 */
export const ReportCard = ({ reportInfo, report }: IReportCardProps) => {
  const data = report?.data;
  const success =
    (reportInfo.status === ReportStatus.SUCCESS && data?.reportType === ReportType.RUN) ||
    (reportInfo.status === ReportStatus.SUCCESS && data?.reportType === ReportType.WALK);
  const error =
    reportInfo.status === ReportStatus.ERROR ||
    data?.reportType === ReportType.ERROR ||
    data?.reportType === ReportType.KNEEL ||
    data?.reportType === ReportType.KNEER;
  const pending = reportInfo.status === ReportStatus.PENDING;
  const activity = data?.reportType === ReportType.RUN ? 'Run' : 'Walk';
  const cardClassName = error ? 'cardError' : 'card';
  const areasOfConcern = data?.areasOfConcern ? data?.areasOfConcern.col0[0] + data?.areasOfConcern.col1[0] : '';
  const reportRUN = data?.reportType === ReportType.RUN || data?.reportType === ReportType.WALK;
  const [downloadModal, setDownloadModal] = useState(false);
  const [showErrorDoDelete, setShowErrorDoDelete] = useState(false);
  const { doDelete } = useReportContext();
  const [showActionSheet, setShowActionSheet] = useState(false);
  const history = useHistory();

  // get the name of the person
  const indexToSlice = reportInfo.filename.indexOf('-');
  const name = reportInfo.filename.slice(0, indexToSlice - 1);

  return (
    <>
      <IonToast
        isOpen={showErrorDoDelete}
        onDidDismiss={() => setShowErrorDoDelete(false)}
        message={`No report id for given report ${reportInfo.filename}, with reportInfo reportID ${reportInfo.reportId}`}
        duration={2000}
      />
      {/* Download modal */}
      <IonModal isOpen={downloadModal} cssClass='download_Modal' onDidDismiss={() => setDownloadModal(false)}>
        <PDFDownloadLink document={<TestDocument report={report} reportInfo={reportInfo} />} fileName={`${name}.pdf`}>
          {({ blob, url, loading, error }) =>
            loading ? (
              <div className='sk-fading-circle'>
                <div className='sk-circle1 sk-circle'></div>
                <div className='sk-circle2 sk-circle'></div>
                <div className='sk-circle3 sk-circle'></div>
                <div className='sk-circle4 sk-circle'></div>
                <div className='sk-circle5 sk-circle'></div>
                <div className='sk-circle6 sk-circle'></div>
                <div className='sk-circle7 sk-circle'></div>
                <div className='sk-circle8 sk-circle'></div>
                <div className='sk-circle9 sk-circle'></div>
                <div className='sk-circle10 sk-circle'></div>
                <div className='sk-circle11 sk-circle'></div>
                <div className='sk-circle12 sk-circle'></div>
              </div>
            ) : (
              <div
                className='download_Modal_Download'
                onClick={() =>
                  setTimeout(() => {
                    setDownloadModal(false);
                  }, 1500)
                }>
                <IonIcon src={cloudDownloadOutline} className='download_Modal_DownloadIcon' />
                <p className='download_Modal_DownloadLink'>Click to download</p>
              </div>
            )
          }
        </PDFDownloadLink>
      </IonModal>
      <IonCard
        // routerLink={success ? `/reports/${reportInfo.id}` : '#'}
        // routerDirection='forward'
        className={cardClassName}>
        <IonCardHeader mode='md' className='reportcardHeader'>
          <IonCardTitle>
            <p>{name}</p>
            {error ? (
              <IonItem lines='none' class='ion-no-padding'>
                <IonIcon icon={alertOutline} slot='start' />
                <IonLabel className='ion-text-wrap errorItem' color='danger'>
                  Sorry, we couldn&#39;t process your report. Please contact support for more information.
                </IonLabel>
              </IonItem>
            ) : (
              !success && <IonSpinner color='secondary' class='spinner' />
            )}
            {success && (
              <>
                <IonButton onClick={() => setShowActionSheet(true)} fill='clear' className='actionButton'>
                  <IonIcon icon={menuOutline} />
                </IonButton>
                <IonActionSheet
                  isOpen={showActionSheet}
                  onDidDismiss={() => setShowActionSheet(false)}
                  mode='ios'
                  buttons={[
                    {
                      text: 'Generate PDF',
                      icon: cloudDownloadOutline,
                      handler: () => {
                        setDownloadModal(true);
                      },
                    },
                    {
                      text: 'Delete',
                      role: 'destructive',
                      icon: trashOutline,
                      handler: () => {
                        if (reportInfo.id) {
                          doDelete(reportInfo.id);
                        } else {
                          setShowErrorDoDelete(true);
                          console.log(
                            `No report id for given report ${reportInfo.filename}, with reportInfo reportID ${reportInfo.reportId}`,
                          );
                        }
                      },
                    },

                    {
                      text: 'Cancel',
                      icon: closeOutline,
                      role: 'cancel',
                      handler: () => {
                        console.log('Cancel clicked');
                      },
                    },
                  ]}></IonActionSheet>
              </>
            )}
            {error && (
              <>
                <IonButton onClick={() => setShowActionSheet(true)} fill='clear' className='actionButton'>
                  <IonIcon icon={menuOutline} />
                </IonButton>
                <IonActionSheet
                  isOpen={showActionSheet}
                  onDidDismiss={() => setShowActionSheet(false)}
                  mode='ios'
                  buttons={[
                    {
                      text: 'Delete',
                      role: 'destructive',
                      icon: trashOutline,
                      handler: () => {
                        if (reportInfo.id) {
                          doDelete(reportInfo.id);
                        } else {
                          setShowErrorDoDelete(true);
                          console.log(
                            `No report id for given report ${reportInfo.filename}, with reportInfo reportID ${reportInfo.reportId}`,
                          );
                        }
                      },
                    },

                    {
                      text: 'Cancel',
                      icon: closeOutline,
                      role: 'cancel',
                      handler: () => {
                        console.log('Cancel clicked');
                      },
                    },
                  ]}></IonActionSheet>
              </>
            )}
          </IonCardTitle>
        </IonCardHeader>

        <IonCardContent
          className='reportcardContent'
          onClick={() => {
            history.push(`/reports/${reportInfo.id}`);
          }}>
          <IonList lines='none'>
            <IonItem>
              <IonIcon icon={calendarOutline} slot='start' />
              <IonLabel>{reportInfo.uploadTimestamp.toLocaleString()}</IonLabel>
            </IonItem>

            {reportRUN && (
              <IonItem>
                <IonIcon icon={bodyOutline} slot='start' />
                <IonLabel>Activity: {activity}</IonLabel>
              </IonItem>
            )}

            {areasOfConcern >= 0 && reportRUN && (
              <IonItem>
                <IonIcon icon={warningOutline} slot='start' />
                <IonLabel>{areasOfConcern} Area(s) of concern</IonLabel>
              </IonItem>
            )}

            {data?.reportType === ReportType.KNEEL && (
              <IonItem>
                <IonIcon icon={warningOutline} slot='start' />
                <IonLabel>Left Thigh and Calf Sensors seem to be swapped.</IonLabel>
              </IonItem>
            )}

            {data?.reportType === ReportType.KNEER && (
              <IonItem>
                <IonIcon icon={warningOutline} slot='start' />
                <IonLabel>Right Thigh and Calf Sensors seem to be swapped.</IonLabel>
              </IonItem>
            )}

            {data?.reportType === ReportType.ERROR && (
              <IonItem>
                <IonIcon icon={warningOutline} slot='start' />
                <IonLabel>Data quality is poor.</IonLabel>
              </IonItem>
            )}

            {reportInfo.tags[0] != '' && (
              <IonItem>
                <IonIcon icon={pricetagsOutline} slot='start' />
                {reportInfo.tags.map((tag) => (
                  <IonChip key={tag}>{tag}</IonChip>
                ))}
              </IonItem>
            )}
          </IonList>
        </IonCardContent>
      </IonCard>
    </>
  );
};
