export type ReportInfoId = string;

export enum ReportStatus {
  ERROR = 'error',
  PENDING = 'pending',
  SUCCESS = 'success',
  DELETED = 'deleted',
}

/** ReportInfo struct holding meta information on the file, processing, and report
 * This is combined from the KinematicsFiles and ReportInfo database collections.
 */
export type ReportInfo = {
  id: ReportInfoId | undefined;
  filename: string;
  tags: string[];
  uploadTimestamp: Date;
  reportId: string | undefined;
  status: ReportStatus;
  errorMessage: string | undefined;
};

/** Dictionary of ReportInfo structs, indexed by ReportInfoId */
export type ReportInfoDict = {
  [id in ReportInfoId]: ReportInfo;
};
