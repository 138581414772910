import React from 'react';
import {
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
  IonGrid,
  IonRow,
  IonCol,
  IonImg,
  IonSlides,
  IonSlide,
} from '@ionic/react';
import './modalindex.css';
import IBimagepositiverunningmidstance1 from './assets/IBimagepositiverunningmidstance1.png';

const slideOpts = {
  initialSlide: 0,
  speed: 400,
  direction: 'vertical',
};

export const ModalPositiveRunningMidstance: React.FC = () => {
  return (
    <>
      <IonCardHeader className='modal_section-header'>
        <div>
          <IonCardTitle>Positive Running</IonCardTitle>
          <IonCardSubtitle>Midstance</IonCardSubtitle>
        </div>
      </IonCardHeader>

      <IonSlides pager={false} options={slideOpts} mode='ios'>
        <IonSlide>
          <IonGrid>
            <IonRow class='ion-align-items-center'>
              <IonCol size='12' size-lg='8' size-xl='8'>
                <div>
                  <IonImg src={IBimagepositiverunningmidstance1} class='modalAvatar' />
                </div>
              </IonCol>
              <IonCol>
                <div>
                  Together will multiple smaller attractors, the scissors contribute to an overall stable pattern called{' '}
                  <i>Positive Running at midstance</i>.<br></br>
                  <br></br>
                  When running at speed, positive running pose is shown as the bisector of both thigh angles. The line
                  that divides the angle into two equal segments should point markedly forward (positive) if underlying
                  attractors are performed well. For each runner there is an ideal direction for this angle.
                  <br></br>
                  <br></br>
                  If technique changes due to fatigue or physical issues, the direction of the bisector will point more
                  downwards (less positive) resulting in decrease in performance.
                </div>
              </IonCol>
            </IonRow>
          </IonGrid>
        </IonSlide>
      </IonSlides>
    </>
  );
};
