import React from 'react';
import {
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
  IonGrid,
  IonRow,
  IonCol,
  IonImg,
  IonSlides,
  IonSlide,
  IonIcon,
} from '@ionic/react';
import './modalindex.css';
import IBimageKneeanglemidswing1 from './assets/IBimageKneeanglemidswing1.png';
import IBimageKneeanglemidswing2 from './assets/IBimageKneeanglemidswing2.png';
import { chevronDownOutline, chevronUpOutline } from 'ionicons/icons';

const slideOpts = {
  initialSlide: 0,
  speed: 400,
  direction: 'vertical',
};

export const ModalKneeFlexionMidswing: React.FC = () => {
  return (
    <>
      <IonCardHeader className='modal_section-header'>
        <div>
          <IonCardTitle>Knee flexion</IonCardTitle>
          <IonCardSubtitle>Midswing</IonCardSubtitle>
        </div>
      </IonCardHeader>

      <IonSlides pager={true} options={slideOpts} mode='ios'>
        <IonSlide>
          <IonGrid>
            <IonRow class='ion-align-items-center'>
              <IonCol size='12' size-lg='8' size-xl='8'>
                <div>
                  <IonImg src={IBimageKneeanglemidswing1} class='modalAvatar' />
                </div>
              </IonCol>
              <IonCol>
                <div>
                  Assessment of the exact knee angle in mid-swing is not necessarily relevant; in contrast to walking,
                  the knee angle shows a lot of variation because it highly depends on the speed.
                  <br></br>
                  <br></br>A proper running technique can be executed with a lot of knee flexion but also at just 90
                  degrees.
                  <br></br>
                  <br></br>
                  However, when the knee angle is smaller than 90 degrees, the leg’s mass will slow down the pace of the
                  pendulum.
                  <br></br>
                  <br></br>
                  In addition an obtuse knee angle will hamper a proper crossed extensor reflex in the following
                  propulsion phase.
                </div>
              </IonCol>
            </IonRow>
            <IonRow class='ion-hide-xl-down'>
              <IonCol class='ion-float-right'>
                <IonIcon icon={chevronDownOutline} className='Modalicon' />
              </IonCol>
            </IonRow>
          </IonGrid>
        </IonSlide>

        <IonSlide>
          <IonGrid>
            <IonRow class='ion-hide-xl-down'>
              <IonCol class='ion-float-right'>
                <IonIcon icon={chevronUpOutline} className='Modalicon' />
              </IonCol>
            </IonRow>
            <IonRow class='ion-align-items-center'>
              <IonCol size='12' size-lg='8' size-xl='8'>
                <div>
                  <IonImg src={IBimageKneeanglemidswing2} class='modalAvatar' />
                </div>
              </IonCol>
              <IonCol>
                <div>
                  The knee angle at midswing is an important parameter when assessing people recovering from knee
                  injuries or surgeries like ACL-reconstructions.
                  <br></br>
                  <br></br>
                  Typically, massive differences in symmetry can reveal the existence of problems.
                </div>
              </IonCol>
            </IonRow>
          </IonGrid>
        </IonSlide>
      </IonSlides>
    </>
  );
};
