import React from 'react';

import { View, StyleSheet, Image, Text } from '@react-pdf/renderer';
import logo from '../../../../../assets/oryx-logo.png';

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    // borderBottomWidth: 1,
    // borderBottomColor: '#E2E2E2',
    // borderBottomStyle: 'solid',
    alignItems: 'center',
    justifyContent: 'space-around',
  },
  logo: {
    width: '100px',
    padding: 10,
  },
  nameColumn: {
    paddingLeft: 10,
    fontFamily: 'Montserrat',
    fontSize: 12,
    color: '#767676',
  },
});

export const Header = () => (
  <View style={styles.container}>
    <Text style={styles.nameColumn}> - ORYX Analysis Report -</Text>
    <Image src={logo} style={styles.logo} />
  </View>
);
