import { IonFooter, IonTitle, IonToolbar } from '@ionic/react';
import React from 'react';
import './index.css';

export const Footer = () => {
  return (
    <IonFooter className='ion-no-border'>
      <IonToolbar color='white'>
        <IonTitle class='ion-text-center' size='small'>
          <div>Developed by ORYX Movement Solutions - &copy; All Rights Reserved</div>
        </IonTitle>
      </IonToolbar>
    </IonFooter>
  );
};
