/** Models for capturing the logged in user and role */
import firebase from 'firebase/app';

export type UserId = string;

export type User = firebase.User;

export enum UserRole {
  ADMIN = 'admin',
  CLIENT = 'client',
  NONE = '',
}

export type UserState = {
  user: User;
  role: UserRole;
};
