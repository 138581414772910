/** Component for the Rear Pendulum Scatter plot */
import * as React from 'react';
import { CartesianGrid, Legend, ResponsiveContainer, Scatter, ScatterChart, XAxis, YAxis } from 'recharts';
import { RearPendulumData, RunReportData } from '../../../model';
import { refLines } from '../components/Graph';

/* Convert data from: (dict of colum ids -> array of values) to
 * an (array of dicts of (column name->value entries and 'x'->index) entries.
 */
function toScatterData(data: RearPendulumData, columns: string[]) {
  return data.col0.map((_value: number, index: number) => {
    return {
      x: data[columns[0]][index],
      y: data[columns[1]][index],
    };
  });
}

// Avoid drawing a dot on the scatter line
// Must accept scatter line props.
const NoDot = () => {
  return <div />;
};

type RearPendulumGraphProps = {
  data: RunReportData;
};

/** Rear pendulum scatter graph
 * @param data - Graph data: rows of {x, y} values
 */
export const RearPendulumGraph = ({ data }: RearPendulumGraphProps) => {
  const scatterDataLeft = toScatterData(data.rearPendulumData, ['col0', 'col1']);
  const scatterDataRight = toScatterData(data.rearPendulumData, ['col2', 'col3']);
  const labels = ['HO', 'TO', 'IC'];

  return (
    <ResponsiveContainer width='99%' height={400}>
      <ScatterChart
        margin={{
          top: 20,
          right: 20,
          bottom: 20,
          left: 20,
        }}>
        <CartesianGrid stroke='#e0e0ff' vertical={false} />
        <XAxis type='number' dataKey='x' label={{ value: 'm', position: 'bottom', offset: 5 }} />
        <YAxis type='number' dataKey='y' label={{ value: 'm', angle: -90, position: 'insideLeft', offset: 5 }} />
        <Scatter name='Left' data={scatterDataLeft} line fill='blue' shape={<NoDot />} />
        <Scatter name='Right' data={scatterDataRight} line fill='green' shape={<NoDot />} />
        {refLines(data.rearPendulumPIs, 'col0', 'blue', labels)}
        {refLines(data.rearPendulumPIs, 'col1', 'green', labels)}
        <Legend
          height={40}
          wrapperStyle={{ bottom: 0, right: 0, width: 'auto' }}
          verticalAlign='bottom'
          iconType='line'
        />
      </ScatterChart>
    </ResponsiveContainer>
  );
};
