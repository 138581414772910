/** Main menu shown on the top right */
import {
  IonContent,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonListHeader,
  IonMenu,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonFooter,
} from '@ionic/react';
import { barChartOutline, globeOutline, logOutOutline, personOutline } from 'ionicons/icons';
import * as React from 'react';
import { useAuthContext } from './authContext';

/** Main menu shown top-right
 * Note: must be linked to the "Nav" item with the same id="main"
 * to be able to be visible.
 * Also, every content item on every page has the same id="main", not
 * fully sure if that is needed.
 */
export const Menu: React.FC = () => {
  const { state, signOut } = useAuthContext();

  return (
    <IonMenu type='overlay' disabled={false} side='end' contentId='main'>
      <IonHeader mode='md'>
        <IonToolbar>
          <IonTitle>Menu</IonTitle>
        </IonToolbar>
      </IonHeader>

      {!!state.userState && (
        <IonList lines='inset'>
          <IonItem detail={false} href='/reports'>
            <IonIcon slot='start' icon={barChartOutline} />
            <IonLabel>Reports</IonLabel>
          </IonItem>
        </IonList>
      )}

      <IonContent forceOverscroll={false}>
        <IonList lines='inset'>
          <IonListHeader>Info</IonListHeader>
          <IonItem href='https://oryxmovementsolutions.com'>
            <IonIcon slot='start' icon={globeOutline} />
            <IonLabel>Oryx Movement Solutions</IonLabel>
          </IonItem>
        </IonList>

        {!!state.userState && (
          <IonList lines='inset'>
            <IonListHeader>Account</IonListHeader>
            <IonItem>
              <IonIcon slot='start' icon={personOutline} />
              <IonLabel className='ion-text-wrap'>{state.userState.user.email}</IonLabel>
            </IonItem>
            <IonItem button onClick={() => signOut()}>
              <IonIcon slot='start' icon={logOutOutline} />
              Sign Out
            </IonItem>
          </IonList>
        )}
      </IonContent>
      <IonFooter>
        <IonToolbar>
          <IonTitle class='ion-text-center' size='small' color='medium'>
            Version: 1.4.5
          </IonTitle>
        </IonToolbar>
      </IonFooter>
    </IonMenu>
  );
};
