/** Main url routes for the application */
import { IonNav } from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import React from 'react';
import { GuardedRoute } from './components/authContext/GuardedRoute';
import { GuestRoute } from './components/authContext/GuestRoute';
import { Menu } from './components/Menu';
import { HomePage } from './pages/Home';
import { LoginPage } from './pages/Login';
import { ReportDetailPage } from './pages/Report';
import { ReportsPage } from './pages/ReportList';
import { UploadPage } from './pages/Upload';
import { ReportProvider } from './components/reportContext';

/** Main url routes for the application
 * FirebaseAuthConsumer provides the userId parameter to the sub pages when a user is logged in.
 * If you are not logged in only the home and login page is rendered.
 */
export const Routes = () => (
  <IonReactRouter>
    <Menu />
    {/* Note: the Nav element is needed for the side menu to know where to display itself */}
    <IonNav id='main' />

    <GuestRoute path='/'>
      <HomePage />
    </GuestRoute>
    <GuestRoute path='/login'>
      <LoginPage />
    </GuestRoute>

    <ReportProvider>
      <GuardedRoute path='/reports'>
        <ReportsPage />
      </GuardedRoute>
      <GuardedRoute path='/reports/:id'>
        <ReportDetailPage />
      </GuardedRoute>
    </ReportProvider>

    <GuardedRoute path='/upload'>
      <UploadPage />
    </GuardedRoute>
  </IonReactRouter>
);
