/** The Run Kinematics report tab */
import { IonCol, IonContent, IonGrid, IonPage, IonRow, IonText, IonItem, IonIcon, IonChip } from '@ionic/react';
import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import WalkEnd from '../../../assets/avatars/run/kinematics/End.png';
import HeelOff from '../../../assets/avatars/run/kinematics/HeelOff.png';
import InitialContact from '../../../assets/avatars/run/kinematics/InitialContact.png';
import Midstance from '../../../assets/avatars/run/kinematics/MidStance.png';
import Midswing from '../../../assets/avatars/run/kinematics/MidSwing.png';
import ToeOff from '../../../assets/avatars/run/kinematics/ToeOff.png';
import { Report, RunReportData } from '../../../model';
import { AttractorLabels, NameField, toAttractorLabels } from '../components/Fields';
import { PelvisGraphs, HipGraphs, KneeGraphs, AnkleGraphs } from '../components/KinematicsGraph';
import { RomTable, SpatioTemporalTableRun } from '../components/KinematicsTable';
import { ReportSection, GraphReportSection, ReportTableSection } from '../components/ReportSection';
import { RearPendulumGraph } from './RearPendulumGraph';
import { SagittalPlaneGraph } from './SagittalPlaneGraph';
import { pricetagsOutline } from 'ionicons/icons';

const Info: React.FC = () => {
  return <p>Under construction, coming soon...</p>;
};

type TextCardProps = {
  title: string;
  info: React.ReactNode;
  graph: React.ReactNode;
  labels: string[];
  children: React.ReactNode;
};

/** Report card showing the avatar, explainer text, Status field, and a graph
 * @param graph - Component with the graph
 * @param labels - Labels for the status field. Tuple of (Left, Right) labels
 * @param children - Components for the explainer text
 */
// TODO: reuse
const TextGraphCard = ({ title, info, graph, labels, children }: TextCardProps) => {
  return (
    <ReportSection title={title} info={info}>
      <IonGrid>
        <IonRow>
          <IonCol>
            <IonGrid>
              <IonRow>
                <IonCol>{children}</IonCol>
              </IonRow>
              <IonRow className='justify-content-center'>
                <IonCol size='12'>
                  <NameField name='Status' value={labels} />
                </IonCol>
              </IonRow>
            </IonGrid>
          </IonCol>
          <IonCol size-xs='12' size-sm='12' size-md='12' size-lg='8' size='12'>
            {graph}
          </IonCol>
        </IonRow>
      </IonGrid>
    </ReportSection>
  );
};

type SagittalPlaneCard = {
  data: RunReportData;
  info: React.ReactNode;
  labels: AttractorLabels;
  children: React.ReactNode;
};

/** Specific report card for the Sagittal Plane
 * Showing explainer text, status fields, and line graphs for knees and ankles.
 * @param labels - Rows of (Left, Right) label tuples, row 0 is knees, 1 is ankles.
 */
const SagittalPlaneCard = ({ data, info, labels, children }: SagittalPlaneCard) => {
  return (
    <ReportSection title='Respect to sagittal plane' info={info}>
      <IonGrid>
        <IonRow>
          <IonCol>
            <IonGrid>
              <IonRow>
                <IonCol>{children}</IonCol>
              </IonRow>
              <IonRow className='justify-content-center ion-padding-top ion-padding-bottom'>
                <IonCol size='12'>
                  <NameField name='Status Knee' value={labels[13]} />
                </IonCol>
              </IonRow>
              <IonRow className='justify-content-center ion-padding-top'>
                <IonCol size='12'>
                  <NameField name='Status Ankle' value={labels[12]} />
                </IonCol>
              </IonRow>
            </IonGrid>
          </IonCol>
          <IonCol size-xs='12' size-sm='12' size-md='12' size-lg='8' size='12' className='ion-padding-top'>
            {<SagittalPlaneGraph data={data} />}
          </IonCol>
        </IonRow>
      </IonGrid>
    </ReportSection>
  );
};

/** Avatars for the kinematics graph */
const avatars = [HeelOff, ToeOff, Midswing, InitialContact, Midstance, WalkEnd];

type KinematicsReportProps = RouteComponentProps & {
  report: Report;
};

/** Run kinematics report tab
 * @param report - the full run report info and data
 */
export const RunKinematicsReportTab = ({ report }: KinematicsReportProps) => {
  const data = report.data as RunReportData | undefined;

  if (!data) {
    return <IonText>Data not found.</IonText>;
  }

  const attractorLabels = toAttractorLabels(data.attractors);

  return (
    <IonPage>
      <IonContent className='ion-padding'>
        {report.tags.length > 0 && (
          <IonItem>
            <IonIcon icon={pricetagsOutline} slot='start' />
            {report.tags.map((tag) => (
              <IonChip key={tag}>{tag}</IonChip>
            ))}
          </IonItem>
        )}

        {data && (
          <IonGrid>
            <IonRow>
              <IonCol>
                <TextGraphCard
                  title='Pendulum'
                  info={<Info />}
                  graph={<RearPendulumGraph data={data} />}
                  labels={attractorLabels[4]}>
                  <IonText>
                    <p>The shape of the pendulum provides many insights on the quality of the running gait.</p>
                    <p>
                      It enables analysis of the trajectory of the rear pendulum and therefore the running economy in
                      the sagittal plane.
                    </p>
                  </IonText>
                </TextGraphCard>
              </IonCol>
            </IonRow>

            <IonRow>
              <IonCol>
                <SagittalPlaneCard info={<Info />} data={data} labels={attractorLabels}>
                  <IonText>
                    <p>
                      When running, no energy should ‘leak’ from the sagittal plane (forward movement) to the
                      transversal movement (rotation).
                    </p>
                    <p>
                      By tracking the trajectory of the knees and feet, the running economy can easily be assessed. If
                      the lines cross ‘zero’, the sagittal plane is disrespected.
                    </p>
                  </IonText>
                </SagittalPlaneCard>
              </IonCol>
            </IonRow>

            <IonRow class='no-margin'>
              <IonCol>
                <GraphReportSection
                  title='Pelvis'
                  info={<Info />}
                  graph={<PelvisGraphs data={data} avatars={avatars} />}></GraphReportSection>
              </IonCol>
            </IonRow>

            <IonRow class='no-margin'>
              <IonCol>
                <GraphReportSection
                  title='Hip'
                  info={<Info />}
                  graph={<HipGraphs data={data} avatars={avatars} />}></GraphReportSection>
              </IonCol>
            </IonRow>

            <IonRow class='no-margin'>
              <IonCol>
                <GraphReportSection
                  title='Knee'
                  info={<Info />}
                  graph={<KneeGraphs data={data} avatars={avatars} />}></GraphReportSection>
              </IonCol>
            </IonRow>

            <IonRow class='no-margin'>
              <IonCol>
                <GraphReportSection
                  title='Ankle'
                  info={<Info />}
                  graph={<AnkleGraphs data={data} avatars={avatars} />}></GraphReportSection>
              </IonCol>
            </IonRow>

            <IonRow class='no-margin'>
              <IonCol size-md='12' size-lg='6'>
                <ReportTableSection title='ROM Table' info={<Info />}>
                  <IonGrid class='ion-no-padding'>
                    <IonRow>
                      <IonCol>
                        <RomTable data={data} />
                      </IonCol>
                    </IonRow>
                  </IonGrid>
                </ReportTableSection>
              </IonCol>

              <IonCol size-md='12' size-lg='6'>
                <ReportTableSection title='Spatio temporal parameters' info={<Info />}>
                  <IonGrid class='ion-no-padding'>
                    <IonRow>
                      <IonCol>
                        <SpatioTemporalTableRun data={data} />
                      </IonCol>
                    </IonRow>
                  </IonGrid>
                </ReportTableSection>
              </IonCol>
            </IonRow>
          </IonGrid>
        )}
      </IonContent>
    </IonPage>
  );
};
