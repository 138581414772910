/** File selection input field for Web */
import { IonButton, IonItem, IonLabel } from '@ionic/react';
import * as React from 'react';
import { useState } from 'react';
import './index.css';

export interface IFileInputProps {
  onChange?: ((event: any) => void) | undefined;
}

/** File selection input field
 * Styled to Ionic style as Ionic does not provide a styled file input widget.
 * Note for native IOS/Android this would need to change to the native
 * file selection widgets.
 */
export const FileInput = ({ onChange }: IFileInputProps) => {
  const [file, setFile] = useState('');

  const handleFileChange = (e: any) => {
    const files = e.target.files;
    if (files && files.length > 0) {
      const filename = e.target.files[0].name;
      setFile(filename);
      onChange && onChange(e);
    }
  };

  return (
    <IonItem>
      <input className='inputfile' type='file' id='file' onChange={handleFileChange} />
      <IonButton>
        <label htmlFor='file'>Select file</label>
      </IonButton>
      <div className='ion-padding'>
        <IonLabel>{file}</IonLabel>
      </div>
    </IonItem>
  );
};
