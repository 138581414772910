import { Document, Font, Image, Page, StyleSheet, Text, View } from '@react-pdf/renderer';
import * as React from 'react';
// import { ReportStatus } from '../../../../model';
import { Report, ReportInfo } from '../../../../model';
import { Header } from './Components/PDFHeader';
import { PDFMobilityStatus } from './Components/PDFMobilityStatus';
// import { ReportCard } from '../../../ReportList/ReportCard';
import { PDFReportCard } from './Components/PDFReportCard';
import { RunAnalysisCards } from './Components/PDFRunAnalysis';
import { WalkAnalysisCards } from './Components/PDFWalkAnalysis';
import { WalkExplainerCards, WalkExplainerCards_Two } from './Components/PDFWalkExplaination';
import logo from './logo new.png';

export const styles = StyleSheet.create({
  frontLogo: { width: '200px', marginHorizontal: 200, marginVertical: 100 },
  ReportCard: { bottom: 50, position: 'absolute' },
  pageNumber: {
    position: 'absolute',
    fontSize: 8,
    bottom: 20,
    left: 0,
    right: 0,
    textAlign: 'center',
    color: '#767676',
  },
  versionNumber: {
    position: 'absolute',
    fontSize: 8,
    bottom: 20,
    left: 0,
    right: 0,
    textAlign: 'center',
    color: '#767676',
    opacity: 0.5,
  },
});

Font.register({
  family: 'Roboto',
  src: `https://fonts.gstatic.com/s/roboto/v19/KFOmCnqEu92Fr1Mu4mxPKTU1Kg.ttf`,
});
Font.register({
  family: 'Montserrat',
  src: `http://fonts.gstatic.com/s/montserrat/v6/zhcz-_WihjSQC0oHJ9TCYC3USBnSvpkopQaUR-2r7iU.ttf`,
});
Font.register({
  family: 'Open Sans',
  src: `https://fonts.gstatic.com/s/opensans/v17/mem8YaGs126MiZpBA-UFVZ0e.ttf`,
});
Font.register({
  family: 'Lato Italic',
  src: `https://fonts.gstatic.com/s/lato/v16/S6u8w4BMUTPHjxsAXC-v.ttf`,
});
Font.register({
  family: 'Lato Bold',
  src: `https://fonts.gstatic.com/s/lato/v16/S6u9w4BMUTPHh6UVSwiPHA.ttf`,
});
Font.register({
  family: 'Montserrat Bold',
  src: `http://fonts.gstatic.com/s/montserrat/v6/IQHow_FEYlDC4Gzy_m8fcvEr6Hm6RMS0v1dtXsGir4g.ttf`,
});

export interface DocumentProps {
  reportInfo: ReportInfo;
  report?: Report;
  pelvisSagGraph?: string;
  // reportDict: ReportInfoDict;
}
//eslint-disable-next-line
export const TestDocument: React.FC<DocumentProps> = ({ report, reportInfo, pelvisSagGraph }) => {
  const data = report?.data;
  if (data?.reportType === 1) {
    return (
      <Document>
        <Page size='A4'>
          <View>
            <Image style={styles.frontLogo} src={logo} />
          </View>
          <View style={styles.ReportCard}>
            <PDFReportCard report={report} reportInfo={reportInfo} />
          </View>
          <Text style={styles.versionNumber}>Versie 1</Text>
        </Page>
        <Page size='A4'>
          <Header />
          <PDFMobilityStatus report={report} />
          <WalkAnalysisCards report={report} />
          <Text style={styles.pageNumber} render={({ pageNumber }) => `- ${pageNumber - 1} -`} fixed />
        </Page>
      </Document>
    );
  } else
    return (
      <Document>
        <Page size='A4'>
          <View>
            <Image style={styles.frontLogo} src={logo} />
          </View>
          <View style={styles.ReportCard}>
            <PDFReportCard report={report} reportInfo={reportInfo} />
          </View>
          <Text style={styles.versionNumber}>Versie 1</Text>
        </Page>
        <Page size='A4'>
          <Header />
          <RunAnalysisCards report={report} />
          <Text style={styles.pageNumber} render={({ pageNumber }) => `- ${pageNumber - 1} -`} fixed />
        </Page>
      </Document>
    );
};
