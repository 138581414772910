/** Card (section) in a report with a title, content, and info button */
import {
  IonButton,
  IonButtons,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
  IonIcon,
  IonModal,
} from '@ionic/react';
import { informationCircleOutline } from 'ionicons/icons';
import * as React from 'react';
import { useState } from 'react';
import './index.css';

type ReportSectionProps = {
  title: string;
  subtitle?: string;
  info: React.ReactNode;
  children: React.ReactNode;
};

type GraphReportSectionProps = {
  title: string;
  subtitle?: string;
  info: React.ReactNode;
  graph: React.ReactNode;
};

type ReportTableSectionProps = {
  title: string;
  subtitle?: string;
  info: React.ReactNode;
  children: React.ReactNode;
};

/** Component for a report section with a title, subtitle, info button and content
 * @param info - The info component, shown as a modal when clicking on the info button
 * @param children - The content of the section
 */
export const ReportSection = ({ title, subtitle, info, children }: ReportSectionProps) => {
  const [showModal, setShowModal] = useState(false);

  return (
    <>
      <IonModal isOpen={showModal} cssClass='section__modal' onDidDismiss={() => setShowModal(false)}>
        {info}
        <IonButton onClick={() => setShowModal(false)}>Close</IonButton>
      </IonModal>

      <IonCard className='section'>
        <IonCardHeader className='section-header'>
          <div>
            <IonCardTitle className='cardTitle'>{title}</IonCardTitle>
            {subtitle && <IonCardSubtitle className='cardSubtitle'>{subtitle}</IonCardSubtitle>}
          </div>
          <IonButtons className='section-header__buttons'>
            <IonButton slot='icon-only' className='section-header__info' onClick={() => setShowModal(true)}>
              <IonIcon icon={informationCircleOutline} />
            </IonButton>
          </IonButtons>
        </IonCardHeader>
        <IonCardContent className='section-content'>{children}</IonCardContent>
      </IonCard>
    </>
  );
};

export const GraphReportSection = ({ title, graph }: GraphReportSectionProps) => {
  return (
    <>
      <IonCard className='section'>
        <IonCardHeader className='section-header_graph'>
          <div>
            <IonCardTitle>{title}</IonCardTitle>
          </div>
        </IonCardHeader>

        <IonCardContent className='graphsection-content'>{graph}</IonCardContent>
      </IonCard>
    </>
  );
};

export const ReportTableSection = ({ title, subtitle, children }: ReportTableSectionProps) => {
  return (
    <>
      <IonCard className='section'>
        <IonCardHeader className='section-header_graph'>
          <div>
            <IonCardTitle>{title}</IonCardTitle>
            {subtitle && <IonCardSubtitle>{subtitle}</IonCardSubtitle>}
          </div>
        </IonCardHeader>
        <IonCardContent className='section-content'>{children}</IonCardContent>
      </IonCard>
    </>
  );
};
