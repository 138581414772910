/** Component for line graphs with reference lines */
import * as React from 'react';
import { useMemo } from 'react';
import { CartesianGrid, Legend, LineChart, ReferenceLine, ResponsiveContainer, XAxis, YAxis } from 'recharts';
import { ReportData } from '../../../model';

export const RefLineLabel = (props: any) => {
  /* Custom reference line label positioned at the top of the graph */
  const { viewBox, value } = props;
  return (
    <text x={viewBox.x} y={viewBox.y} dy={0} fontSize={10} textAnchor='middle'>
      {value}
    </text>
  );
};

export function refLines(data: any, colId: string, color: string, labels?: string[]) {
  return data[colId].map((value: number, index: number) => {
    return (
      <ReferenceLine
        key={`${colId}-${value}`}
        x={value}
        label={labels ? <RefLineLabel value={labels[index]} /> : ''}
        stroke={color}
        strokeDasharray='5 2'
      />
    );
  });
}

type GraphData = any;

/* Convert data from: (dict of colum ids -> array of values) to
 * an array of {x: value, colId: value} dict entries.
 */
function toGraphData(data: any): GraphData {
  return data.col0.map((value: number, index: number) => {
    const entry: GraphData = {};
    entry.x = index;
    Object.keys(data)
      .sort()
      .forEach((colId: string) => {
        entry[colId] = data[colId][index];
      });
    return entry;
  });
}

type GraphProps = {
  data: ReportData;
  dataKey: string;
  syncId?: string;
  width?: string | number;
  height?: string | number;
  children?: React.ReactNode;
};

/** Wrapper around the Recharts.org Linechart
 * @param data - Walk/Run report data from the database
 * @param dataKey - Report data key for which to draw the graph, e.g., kinematicsData
 * @param width - Width of the graph in pixels (number) or as a percentage of the container (string)
 * @param height - Height of the graph in pixels (number) or as a percentage of the container (string)
 * @param children - Linechart components such as xaxis, line, tooltip, etc.
 * Note the default width of 99% and a fixed height makes the graph responsive,
 * the 99% seems a workaround for a bug in the container when used in grids like IonGrid.
 */
export const Graph = ({ data, dataKey, syncId, width = '99%', height, children }: GraphProps) => {
  const graphInput = data[dataKey];
  const graphData = useMemo(() => toGraphData(graphInput), graphInput);

  return (
    <ResponsiveContainer width={width} height={height} className='ResponsiveContainer'>
      <LineChart
        data={graphData}
        syncId={syncId}
        margin={{
          top: 10,
          right: 30,
          left: 0,
          bottom: 0,
        }}>
        {children}
      </LineChart>
    </ResponsiveContainer>
  );
};

type LineGraphProps = {
  data: ReportData;
  dataKey: string;
  syncId: string;
  yLabel: string;
  xLabel: string;
  width?: string | number;
  height?: string | number;
  children: React.ReactNode;
};

/** Wrapper around a recharts.org LineGraph with x-axis, y-axis and Left/Right reference lines
 * @param data - Walk/Run report data from the database
 * @param dataKey - Report data key for which to draw the graph, e.g., kinematicsData
 * @param children - Recharts components for the actual lines and others, such as a Legend
 * @param syncId - Some string, used to synchronize multiple graphs
 * @param yLabel - Label on the y-axis
 * @param width - Width of the graph in pixels (number) or as a percentage of the container (string)
 * @param height - Height of the graph in pixels (number) or as a percentage of the container (string)
 */
export const LineGraph = ({ data, dataKey, syncId, yLabel, xLabel, width, height, children }: LineGraphProps) => {
  const labels = ['HO', 'TO', 'MSw', 'IC', 'MSt'];
  return (
    <Graph data={data} dataKey={dataKey} syncId={syncId} width={width} height={height}>
      <CartesianGrid stroke='#e0e0ff' vertical={false} />
      <Legend
        height={30}
        wrapperStyle={{ bottom: -10, width: 'auto' }}
        verticalAlign='bottom'
        iconType='line'
        formatter={(_value, _entry, index) => (index ? 'Right' : 'Left')}
      />
      <XAxis dataKey='x' interval={19} label={{ value: xLabel, angle: 0, position: 'bottom', offset: 5 }} />
      <YAxis label={{ value: yLabel, angle: -90, position: 'insideLeft', offset: 5 }} />
      <ReferenceLine y={0} stroke='grey' />
      {refLines(data.pointsOfInterests, 'col0', 'blue', labels)}
      {refLines(data.pointsOfInterests, 'col1', 'green')}
      {children}
    </Graph>
  );
};
