/** Reports page, showing metadata for available reports for the current user */
import { IonButton, IonCol, IonContent, IonGrid, IonPage, IonRow, IonSearchbar, IonToast } from '@ionic/react';
import * as React from 'react';
import { useMemo } from 'react';
import { useHistory } from 'react-router';
import { OryxHeader } from '../../components/Header';
import { useReportContext } from '../../components/reportContext';
import { useSearch } from '../../hooks/useSearch';
import { ReportInfo, ReportInfoDict } from '../../model';
import { ReportCard } from './ReportCard';

/** Map over reports sorted by uploadTimestamp */
const sortReportInfos = (reportInfoDict: ReportInfoDict): ReportInfo[] => {
  // Create array of tuples (id, reportInfo)
  const items: [string, ReportInfo][] = Object.keys(reportInfoDict).map((id: string) => {
    return [id, reportInfoDict[id]];
  });

  // Sort the array based on the second element (reportInfo.uploadTimestamp)
  const reportsSorted = items.sort((first, second) => {
    const a = second[1].uploadTimestamp.getTime();
    const b = first[1].uploadTimestamp.getTime();
    return a - b;
  });

  return reportsSorted.map(([_id, reportInfo]) => reportInfo);
};

/** Reports page, showing all reports (metadata) available for the current user
 * Loading reportInfo data from the database using the useReportInfo hook.
 */
export const ReportsPage = () => {
  const history = useHistory();
  const { reportInfoDict, reportDict, isLoading, isError, clearError } = useReportContext();

  const options = {
    includeScore: true,
    useExtendedSearch: true,
    keys: ['filename', 'tags'],
  };
  const list = useMemo(() => sortReportInfos(reportInfoDict), [reportInfoDict]);
  const { onSearch, query, searchResult } = useSearch(options, list);

  return (
    <IonPage>
      <OryxHeader loading={isLoading === 'reportInfo'} />
      <IonContent className='ion-padding' id='main'>
        <IonGrid fixed>
          <IonRow>
            <IonCol class='ion-padding'>
              <IonButton
                onClick={() => {
                  history.push('/upload');
                }}>
                Upload Kinematics
              </IonButton>
            </IonCol>

            <IonCol>
              <IonSearchbar value={query} onIonChange={(e) => onSearch(e)} showCancelButton='focus'></IonSearchbar>
            </IonCol>
          </IonRow>
        </IonGrid>

        <IonGrid fixed>
          <IonRow>
            {searchResult.map((reportInfo) => {
              const report = reportInfo.reportId ? reportDict[reportInfo.reportId] : undefined;
              return (
                <IonCol size='12' size-md='6' key={reportInfo.id}>
                  <ReportCard reportInfo={reportInfo} report={report} />
                </IonCol>
              );
            })}
          </IonRow>
        </IonGrid>
      </IonContent>

      {/* Show errors, if any, as "toast" below the page */}
      <IonToast
        isOpen={isError ? true : false}
        onDidDismiss={() => clearError()}
        message={isError && isError.message}
        color='danger'
        position='bottom'
        buttons={[
          {
            text: 'Close',
            role: 'cancel',
          },
        ]}
      />
    </IonPage>
  );
};
