/** Run analysis report tab */
import {
  IonButton,
  IonButtons,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
  IonChip,
  IonCol,
  IonContent,
  IonGrid,
  IonIcon,
  IonImg,
  IonItem,
  IonModal,
  IonPage,
  IonRow,
  IonText,
} from '@ionic/react';
import { informationCircleOutline, pricetagsOutline } from 'ionicons/icons';
import * as React from 'react';
import { useState } from 'react';
import { RouteComponentProps } from 'react-router';
import ankleStiffness from '../../../assets/avatars/run/analysis/AnkleStiffness.png';
import footPlant from '../../../assets/avatars/run/analysis/FootPlant.png';
import hipLock from '../../../assets/avatars/run/analysis/HipLock.png';
import kneeAngleMidswing from '../../../assets/avatars/run/analysis/KneeAngleMidswing.png';
import kneeExtensionPreswing from '../../../assets/avatars/run/analysis/KneeExtensionPreswing.png';
import kneeFlexionMidstance from '../../../assets/avatars/run/analysis/kneeFlexionMidstance.png';
import positiveRunningMidstance from '../../../assets/avatars/run/analysis/PositiveRunningMidstance.png';
import positiveRunningToeOff from '../../../assets/avatars/run/analysis/PositiveRunningToeOff.png';
import projectionFootplant from '../../../assets/avatars/run/analysis/ProjectionFootplant.png';
import scissors from '../../../assets/avatars/run/analysis/Scissors.png';
import swingLegRecovery from '../../../assets/avatars/run/analysis/SwingLegRecovery.png';
import swingLegRetraction from '../../../assets/avatars/run/analysis/SwingLegRetraction.png';
import { transpose } from '../../../hooks/useReports';
import { Report, RunReportData } from '../../../model';
import { NameField, toAttractorLabels } from '../components/Fields';
import {
  ModalFootPlant,
  ModalHipLock,
  ModalKneeExtensionPreswing,
  ModalKneeFlexionMidswing,
  ModalPositiveRunningMidstance,
  ModalPositiveRunningToeoff,
  ModalProjectionFootplant,
  ModalScissors,
  ModalSwingLegRecovery,
  ModalSwingLegRetraction,
} from '../components/ModalsRun';
import { ModalAnkleStiffness } from '../components/ModalsRun/ModalAnkleStiffness';
import './RunAnalysisReportTab.css';

// type AttractorCardProps = {
//   title: string;
//   subtitle?: string;
//   info: React.ReactNode;
//   img: string;
//   labels: string[];
// };

// /** Card in the analysis page to show the avatar and attractor field
//  * @param img - The avatar image
//  * @param labels - Attractor labels for the status field, tuple of (Left, Right) values
//  */
// const AttractorCard = ({ title, subtitle, info, img, labels }: AttractorCardProps) => {
//   return (
//     <ReportSection title={title} subtitle={subtitle} info={info}>
//       <IonGrid class='ion-no-padding'>
//         <IonRow align-items-center>
//           <IonCol>
//             <IonImg src={img} class='avatar' />
//           </IonCol>
//         </IonRow>

//         <IonRow align-items-center>
//           <IonCol>
//             <NameField name='Status' value={labels} />
//           </IonCol>
//         </IonRow>
//       </IonGrid>
//     </ReportSection>
//   );
// };

type AttractorCardProps_v2 = {
  title: string;
  subtitle?: string;
  info: React.ReactNode;
  img: string;
  labels: string[];
  attractorRow: number[];
};

// TO DO: change the error const to a better solution....
/** Card in the analysis page to show the avatar and attractor field
 * @param img - The avatar image
 * @param labels - Attractor labels for the status field, tuple of (Left, Right) values
 */
const AttractorCard_v1 = ({ title, subtitle, info, img, labels, attractorRow }: AttractorCardProps_v2) => {
  const [showModal, setShowModal] = useState(false);

  const error = attractorRow[0] >= 3 || attractorRow[1] >= 3 ? 'error' : '';
  const cardClassName = error ? 'sectionerror' : 'section';

  return (
    <>
      <IonModal isOpen={showModal} cssClass='section__modal' onDidDismiss={() => setShowModal(false)}>
        {info}
        <IonButton onClick={() => setShowModal(false)}>Close</IonButton>
      </IonModal>

      <IonCard className={cardClassName}>
        <IonCardHeader className='section-header'>
          <div>
            <IonCardTitle className='cardTitle'>{title}</IonCardTitle>
            {subtitle && <IonCardSubtitle className='cardSubtitle'>{subtitle}</IonCardSubtitle>}
          </div>
          <IonButtons className='section-header__buttons'>
            <IonButton slot='icon-only' className='section-header__info' onClick={() => setShowModal(true)}>
              <IonIcon icon={informationCircleOutline} />
            </IonButton>
          </IonButtons>
        </IonCardHeader>
        <IonCardContent className='section-content'>
          <IonGrid class='ion-no-padding'>
            <IonRow align-items-center>
              <IonCol>
                <IonImg src={img} class='avatar' />
              </IonCol>
            </IonRow>

            <IonRow align-items-center>
              <IonCol size='6'>
                <NameField name='Left' value={labels[0]} />
              </IonCol>
              <IonCol size='6'>
                <NameField name='Right' value={labels[1]} />
              </IonCol>
            </IonRow>
          </IonGrid>
        </IonCardContent>
      </IonCard>
    </>
  );
};

/** Card in the analysis page to show the avatar and attractor field
 * @param img - The avatar image
 * @param labels - Attractor labels for the status field, tuple of (Left, Right) values
 */
const AttractorCard_v2 = ({ title, subtitle, info, img, labels, attractorRow }: AttractorCardProps_v2) => {
  const [showModal, setShowModal] = useState(false);

  const error = attractorRow[0] >= 2 || attractorRow[1] >= 2 ? 'error' : '';
  const cardClassName = error ? 'sectionerror' : 'section';

  return (
    <>
      <IonModal isOpen={showModal} cssClass='section__modal' onDidDismiss={() => setShowModal(false)}>
        {info}
        <IonButton onClick={() => setShowModal(false)}>Close</IonButton>
      </IonModal>

      <IonCard className={cardClassName}>
        <IonCardHeader className='section-header'>
          <div>
            <IonCardTitle className='cardTitle'>{title}</IonCardTitle>
            {subtitle && <IonCardSubtitle className='cardSubtitle'>{subtitle}</IonCardSubtitle>}
          </div>
          <IonButtons className='section-header__buttons'>
            <IonButton slot='icon-only' className='section-header__info' onClick={() => setShowModal(true)}>
              <IonIcon icon={informationCircleOutline} />
            </IonButton>
          </IonButtons>
        </IonCardHeader>
        <IonCardContent className='section-content'>
          <IonGrid class='ion-no-padding'>
            <IonRow align-items-center>
              <IonCol>
                <IonImg src={img} class='avatar' />
              </IonCol>
            </IonRow>

            <IonRow align-items-center>
              <IonCol size='6'>
                <NameField name='Left' value={labels[0]} />
              </IonCol>
              <IonCol size='6'>
                <NameField name='Right' value={labels[1]} />
              </IonCol>
            </IonRow>
          </IonGrid>
        </IonCardContent>
      </IonCard>
    </>
  );
};

type RunAnalysisReportProps = RouteComponentProps & {
  report: Report;
};

/** The run analysis report page
 * @param report - The full report info and data
 */
export const RunAnalysisReportTab = ({ report }: RunAnalysisReportProps) => {
  const data = report.data as RunReportData | undefined;
  const attractorData = transpose(data?.attractors);

  if (!data) {
    return <IonText>Data not found.</IonText>;
  }

  const attractorLabels = toAttractorLabels(data.attractors);

  return (
    <IonPage>
      <IonContent className='ion-padding'>
        {report.tags.length > 0 && (
          <IonItem>
            <IonIcon icon={pricetagsOutline} slot='start' />
            {report.tags.map((tag) => (
              <IonChip key={tag}>{tag}</IonChip>
            ))}
          </IonItem>
        )}

        {data && (
          <IonGrid>
            <IonRow>
              <IonCol size-sm='12' size-md='6' size-lg='3'>
                <AttractorCard_v2
                  title='Ankle stiffness'
                  info={<ModalAnkleStiffness />}
                  img={ankleStiffness}
                  labels={attractorLabels[1]}
                  attractorRow={attractorData[1]}></AttractorCard_v2>
              </IonCol>
              <IonCol size-sm='12' size-md='6' size-lg='3'>
                <AttractorCard_v1
                  title='Knee extension'
                  subtitle='Pre-swing'
                  info={<ModalKneeExtensionPreswing />}
                  img={kneeExtensionPreswing}
                  labels={attractorLabels[2]}
                  attractorRow={attractorData[2]}></AttractorCard_v1>
              </IonCol>
              <IonCol size-sm='12' size-md='6' size-lg='3'>
                <AttractorCard_v1
                  title='Hip lock'
                  info={<ModalHipLock />}
                  img={hipLock}
                  labels={attractorLabels[3]}
                  attractorRow={attractorData[3]}></AttractorCard_v1>
              </IonCol>
              <IonCol size-sm='12' size-md='6' size-lg='3'>
                <AttractorCard_v2
                  title='Positive running'
                  subtitle='Toe-off'
                  info={<ModalPositiveRunningToeoff />}
                  img={positiveRunningToeOff}
                  labels={attractorLabels[0]}
                  attractorRow={attractorData[0]}></AttractorCard_v2>
              </IonCol>
            </IonRow>

            <IonRow>
              <IonCol size-sm='12' size-md='6' size-lg='3'>
                <AttractorCard_v2
                  title='Swing leg recovery'
                  info={<ModalSwingLegRecovery />}
                  img={swingLegRecovery}
                  labels={attractorLabels[5]}
                  attractorRow={attractorData[5]}></AttractorCard_v2>
              </IonCol>
              <IonCol size-sm='12' size-md='6' size-lg='3'>
                <AttractorCard_v2
                  title='Knee flexion'
                  subtitle='Midswing'
                  info={<ModalKneeFlexionMidswing />}
                  img={kneeAngleMidswing}
                  labels={attractorLabels[6]}
                  attractorRow={attractorData[6]}></AttractorCard_v2>
              </IonCol>
              <IonCol size-sm='12' size-md='6' size-lg='3'>
                <AttractorCard_v2
                  title='Swing leg retraction'
                  info={<ModalSwingLegRetraction />}
                  img={swingLegRetraction}
                  labels={attractorLabels[9]}
                  attractorRow={attractorData[9]}></AttractorCard_v2>
              </IonCol>
              <IonCol size-sm='12' size-md='6' size-lg='3'>
                <AttractorCard_v1
                  title='Scissors motion'
                  info={<ModalScissors />}
                  img={scissors}
                  labels={attractorLabels[10]}
                  attractorRow={attractorData[10]}></AttractorCard_v1>
              </IonCol>
            </IonRow>

            <IonRow>
              <IonCol size-sm='12' size-md='6' size-lg='3'>
                <AttractorCard_v2
                  title='Foot plant from above'
                  info={<ModalFootPlant />}
                  img={footPlant}
                  labels={attractorLabels[11]}
                  attractorRow={attractorData[11]}></AttractorCard_v2>
              </IonCol>
              <IonCol size-sm='12' size-md='6' size-lg='3'>
                <AttractorCard_v2
                  title='Projection footplant'
                  info={<ModalProjectionFootplant />}
                  img={projectionFootplant}
                  labels={attractorLabels[8]}
                  attractorRow={attractorData[8]}></AttractorCard_v2>
              </IonCol>
              <IonCol size-sm='12' size-md='6' size-lg='3'>
                <AttractorCard_v2
                  title='Knee flexion'
                  subtitle='Midstance'
                  info={<ModalKneeFlexionMidswing />}
                  img={kneeFlexionMidstance}
                  labels={attractorLabels[14]}
                  attractorRow={attractorData[14]}></AttractorCard_v2>
              </IonCol>
              <IonCol size-sm='12' size-md='6' size-lg='3'>
                <AttractorCard_v2
                  title='Positive running'
                  subtitle='Mid-stance'
                  info={<ModalPositiveRunningMidstance />}
                  img={positiveRunningMidstance}
                  labels={attractorLabels[7]}
                  attractorRow={attractorData[7]}></AttractorCard_v2>
              </IonCol>
            </IonRow>
          </IonGrid>
        )}
      </IonContent>
    </IonPage>
  );
};
