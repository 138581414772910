/** Login form for sign-in with email & password */
import { IonButton, IonCol, IonGrid, IonIcon, IonImg, IonInput, IonItem, IonList, IonRow, IonText } from '@ionic/react';
import React, { useState } from 'react';
import { useAuthContext } from '../../components/authContext';
import logo from '../../assets/oryx-logo.png';
import './loginForm.css';
import { lockClosedOutline, mailOutline } from 'ionicons/icons';
import { Footer } from '../../components/Header/Footer';

export const LoginForm = () => {
  const [formSubmitted, setFormSubmitted] = useState(false);

  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');

  const { signInWithEmailAndPassword } = useAuthContext();

  function login(e: React.FormEvent) {
    e.preventDefault();
    setFormSubmitted(true);
    if (!!email && !!password) {
      signInWithEmailAndPassword(email, password);
    }
  }

  const handleEmail = (e: any) => {
    setEmail(e.detail.value);
  };

  const handlePassword = (e: any) => {
    setPassword(e.detail.value);
  };

  return (
    <form noValidate onSubmit={login}>
      <IonGrid fixed className='login_form'>
        <IonRow>
          <IonCol size='12' className='ion-padding'>
            <IonImg src={logo} className='login_logo'></IonImg>
          </IonCol>
        </IonRow>

        <IonRow>
          <IonCol size='12' className='ion-padding'>
            <div>Welcome back! Please sign in to your account.</div>
          </IonCol>
        </IonRow>

        <IonRow>
          <IonCol size='12' className='ion-padding'>
            <IonList>
              <IonItem lines='full' className='ion-padding-top'>
                <IonIcon icon={mailOutline} slot='start'></IonIcon>
                <IonInput name='email' type='email' placeholder='Email' onIonChange={handleEmail}></IonInput>
              </IonItem>
              <IonItem lines='full' className='ion-padding-top'>
                <IonIcon icon={lockClosedOutline} slot='start'></IonIcon>
                <IonInput
                  name='password'
                  type='password'
                  placeholder='Password'
                  onIonChange={handlePassword}></IonInput>
              </IonItem>

              {formSubmitted && (!email || !password) && (
                <IonText color='danger'>
                  <p className='ion-padding-start'>Email and password are required.</p>
                </IonText>
              )}
            </IonList>
          </IonCol>
        </IonRow>

        <IonRow>
          <IonCol size='6' className='ion-padding'>
            <IonButton type='submit' expand='block'>
              Sign in
            </IonButton>
          </IonCol>
        </IonRow>
      </IonGrid>
      <Footer />
    </form>
  );
};
