/** Store for user authentication and user roles */
import { useReducer } from 'react';
import { UserState } from '../model/user.model';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function setAuthed(userState: UserState) {
  return <const>{ type: 'authed', userState };
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function setUnAuthed() {
  return <const>{ type: 'unAuthed' };
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function setLoading() {
  return <const>{ type: 'loading' };
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function setError(error: string) {
  return <const>{ type: 'error', error };
}

export type AuthState = {
  isInitialLoading: boolean;
  isLoading: boolean;
  error: string | undefined;
  userState: UserState | undefined;
};

export const initialState: AuthState = {
  isInitialLoading: true,
  isLoading: false,
  error: undefined,
  userState: undefined,
};

type Action = ReturnType<typeof setAuthed | typeof setUnAuthed | typeof setLoading | typeof setError>;

function reducer(state: AuthState, action: Action): AuthState {
  switch (action.type) {
    case 'authed':
      return {
        ...state,
        isInitialLoading: false,
        isLoading: false,
        userState: action.userState,
      };
    case 'unAuthed':
      return {
        ...state,
        isInitialLoading: false,
        isLoading: false,
        userState: undefined,
      };
    case 'loading':
      return {
        ...state,
        isLoading: true,
      };
    case 'error':
      return {
        ...state,
        isLoading: false,
        error: action.error,
      };
    default:
      return state;
  }
}
// eslint-disable-next-line
export function useAuthReducer(state = initialState) {
  return useReducer(reducer, state);
}
