/** Login page */
import { IonContent, IonPage, IonText, IonGrid, IonCol, IonRow, IonImg } from '@ionic/react';
import React from 'react';
import { Link } from 'react-router-dom';
import { useAuthContext } from '../../components/authContext';
import { ErrorToast } from '../../components/ErrorToast';
import { UserRole } from '../../model/user.model';
import { LoginForm } from './LoginForm';
import login_bg from '../../assets/login/login_bg.png';
import login_image from '../../assets/login/login_image.png';
import './login.css';

export const LoginPage: React.FC = () => {
  const { state, clearError } = useAuthContext();

  return (
    <IonPage>
      <IonContent className='login ion-no-padding' id='main'>
        <IonGrid fixed className='ion-no-padding ion-no-margin'>
          <IonRow>
            <IonCol size='6' className='login-col_left ion-hide-md-down'>
              <IonImg src={login_bg} className='intro_bg'></IonImg>
              <IonImg src={login_image} className='intro_image'></IonImg>
            </IonCol>

            <IonCol size-md='6' size-sm='12' className='login-col'>
              {state.userState ? (
                state.userState.role === UserRole.CLIENT || state.userState.role === UserRole.ADMIN ? (
                  <IonText>
                    <p>You are already signed in.</p>
                  </IonText>
                ) : (
                  <IonText>
                    <p>
                      Sorry, you are not authorized to use this service. Please{' '}
                      <Link to='mailto:info@oryxmovementsolutions.nl'>contact us</Link> to get access.
                    </p>
                  </IonText>
                )
              ) : (
                <>
                  <LoginForm />
                </>
              )}
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>

      <ErrorToast message={state.error} clearError={clearError} />
    </IonPage>
  );
};
