/** Load (and keep listening) all report entries for a given user from the DB */
import 'firebase/firestore';
import { useMemo, useState } from 'react';
import { useCollectionData } from 'react-firebase-hooks/firestore';
import { ReportData, ReportDict, ReportId, ReportInfo, ReportInfoDict, UserId } from '../../model';
import { myFirebase } from '../firebaseConfig';

const db = myFirebase.firestore();

type ReportDataDict = {
  [id in ReportId]: ReportData;
};

export type UseReportsHook = {
  reportDict: ReportDict;
  isLoading: boolean;
  isError: Error | undefined;
  clearError: () => void;
  doDelete: (reportId: string) => Promise<void>;
};

export const useReports = (userId: UserId, reportInfoDict: ReportInfoDict): UseReportsHook => {
  const userRef = db.collection('users').doc(userId);
  const reportsRef = userRef.collection('reports');
  const [reportDocs, loading, error] = useCollectionData<ReportData>(reportsRef, { idField: 'id' });
  const [isError, setIsError] = useState<Error | undefined>();

  const doDelete = async (reportId: string) => {
    const reportInfoDelete = await userRef.collection('reportInfo').doc(reportId);
    reportInfoDelete
      .update({ status: 'deleted' })
      .then((_doc) => {
        console.log('status changed to deleted!');
      })
      .catch((error) => {
        console.log(error);
      });
  };
  // Called to close the error Toast
  const clearError = () => {
    setIsError(undefined);
  };

  if (error) {
    setIsError(error);
  }

  const convertReports = (): ReportDict => {
    if (!reportDocs) {
      return {};
    }
    const reportDataDict: ReportDataDict = reportDocs.reduce(
      (reportDataDict: ReportDataDict, reportData: ReportData) => {
        reportDataDict[reportData.id] = reportData;
        return reportDataDict;
      },
      {},
    );
    return Object.values(reportInfoDict).reduce((reportDict: ReportDict, reportInfo: ReportInfo) => {
      const reportId = reportInfo.reportId;
      if (!reportId) {
        return reportDict;
      }
      const reportData = reportDataDict[reportId];
      if (!reportData) {
        return reportDict;
      }
      reportDict[reportId] = {
        ...reportInfo,
        data: reportData,
      };
      return reportDict;
    }, {});
  };

  const reportDict = useMemo(convertReports, [userId, reportInfoDict, reportDocs]);
  const hook: UseReportsHook = {
    reportDict: reportDict,
    isLoading: loading,
    isError: isError,
    clearError: clearError,
    doDelete: doDelete,
  };
  return useMemo(() => hook, [reportDict, loading, isError]);
};
