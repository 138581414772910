/** Loading page for initial loading, used in AuthProvider */
import { IonHeader, IonImg, IonPage, IonProgressBar, IonTitle, IonToolbar } from '@ionic/react';
import * as React from 'react';
import logo from '../../assets/oryx-logo.png';
import { Footer } from '../../components/Header/Footer';
import './index.css';

/** Loading page with header */
export const LoadingPage = () => {
  return (
    <IonPage>
      <IonHeader mode='md'>
        <IonToolbar className='toolbar'>
          <IonTitle>
            <a href='/'>
              <IonImg src={logo} className='header__logo' />
            </a>
          </IonTitle>
        </IonToolbar>
        <IonProgressBar type='indeterminate'></IonProgressBar>
      </IonHeader>

      <Footer />
    </IonPage>
  );
};
