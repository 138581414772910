/** Main header used on all pages */
import {
  IonButton,
  IonButtons,
  IonHeader,
  IonIcon,
  IonImg,
  IonMenuButton,
  IonProgressBar,
  IonTitle,
  IonToolbar,
} from '@ionic/react';
import { arrowBack, logInOutline } from 'ionicons/icons';
import * as React from 'react';
import { useHistory } from 'react-router';
import { useLocation } from 'react-router-dom';
import logo from '../../assets/oryx-logo.png';
import { useAuthContext } from '../authContext';
import './index.css';

type HeaderProps = {
  loading?: boolean;
  progress?: number;
  backTarget?: string;
  children?: React.ReactNode;
};

/** Main header used on all pages
 * Providing the back button if needed, logo, and menu button.
 * Shows the Login button instead of the menu if not authenticated.
 */
export const OryxHeader = ({ loading, progress, backTarget, children }: HeaderProps) => {
  const history = useHistory();
  const location = useLocation();
  const userState = useAuthContext().state.userState;

  const goBack = (backTarget: string) => {
    history.push(backTarget);
  };

  return (
    <IonHeader mode='md'>
      <IonToolbar>
        {!userState && location.pathname !== '/login' ? (
          <IonButtons slot='end'>
            <IonButton
              onClick={() => {
                history.push('/login');
              }}>
              <IonIcon slot='end' icon={logInOutline} />
              Login
            </IonButton>
          </IonButtons>
        ) : (
          <>
            {backTarget && (
              <IonButtons slot='start'>
                <IonButton shape='round' onClick={() => goBack(backTarget)}>
                  <IonIcon icon={arrowBack} />
                </IonButton>
              </IonButtons>
            )}
            <IonButtons slot='end'>
              <IonMenuButton autoHide={false}></IonMenuButton>
            </IonButtons>
          </>
        )}
        <IonTitle>
          <a href='/'>
            <IonImg src={logo} className='header__logo' />
          </a>
          {children}
        </IonTitle>
      </IonToolbar>

      {loading &&
        (progress !== undefined ? (
          <IonProgressBar value={progress}></IonProgressBar>
        ) : (
          <IonProgressBar type='indeterminate'></IonProgressBar>
        ))}
    </IonHeader>
  );
};
