import React from 'react';
import {
  IonCardHeader,
  IonCardTitle,
  IonGrid,
  IonRow,
  IonCol,
  IonImg,
  IonSlide,
  IonSlides,
  IonCardSubtitle,
  IonIcon,
} from '@ionic/react';
import './modalindex.css';
import IBKneeflexionatmidswing1 from './assets/IBKneeflexionatmidswing1.png';
import IBKneeflexionatmidswing2 from './assets/IBKneeflexionatmidswing2.png';
import { chevronDownOutline, chevronUpOutline } from 'ionicons/icons';

const slideOpts = {
  initialSlide: 0,
  speed: 400,
  direction: 'vertical',
};

export const ModalKneeFlexionMidswing: React.FC = () => {
  return (
    <>
      <IonCardHeader className='section-header'>
        <div>
          <IonCardTitle>Knee Angle</IonCardTitle>
          <IonCardSubtitle>Midswing</IonCardSubtitle>
        </div>
      </IonCardHeader>

      <IonSlides pager={true} options={slideOpts} mode='ios'>
        <IonSlide>
          <IonGrid>
            <IonRow class='ion-align-items-center'>
              <IonCol size='12' size-lg='7' size-xl='7'>
                <div>
                  <IonImg src={IBKneeflexionatmidswing1} class='modalAvatar' />
                </div>
              </IonCol>
              <IonCol>
                <div>
                  The difference in velocity between upper and lower leg, results in bending of the knee (due to its
                  mass, the lower leg will slow down). The more dynamic the push off is – emerging from proper
                  proximodistal sequence (also see: hip extension at terminal stance and knee extension at pre-swing) –
                  the more knee angle will be accomplished at midswing. The amount of angle is relevant for two major
                  reasons.
                  <br></br>
                  <br></br>
                  When the leg swings forward, the foot clearance must be sufficient to prevent stumbling due to
                  catching obstacles. The size of the knee angle determines to a significant extent the amount of foot
                  clearance. Given that the foot never leaves the ground more than 1 cm, it indicates how crucial
                  sufficient knee angle is.
                </div>
              </IonCol>
            </IonRow>
            <IonRow class='ion-hide-xl-down'>
              <IonCol class='ion-float-right'>
                <IonIcon icon={chevronDownOutline} className='Modalicon' />
              </IonCol>
            </IonRow>
          </IonGrid>
        </IonSlide>

        <IonSlide>
          <IonGrid>
            <IonRow class='ion-hide-xl-down'>
              <IonCol class='ion-float-right'>
                <IonIcon icon={chevronUpOutline} className='Modalicon' />
              </IonCol>
            </IonRow>
            <IonRow class='ion-align-items-center'>
              <IonCol size='12' size-lg='7' size-xl='7'>
                <div>
                  <IonImg src={IBKneeflexionatmidswing2} class='modalAvatar' />
                </div>
              </IonCol>
              <IonCol>
                <div>
                  A knee flexing within healthy limits also ensures enough space (between body and ground) for the leg
                  to swing forward in a straight line under the body’s center of mass. A decreasing knee angle (&lt;
                  60°) limits the possibility for the leg to swing under the body. To prevent stumbling, the leg will
                  move forward around the body. The more this range of motion in frontal plane increases, the more
                  balance will be distorted.
                  <br></br>
                  <br></br>
                  It should be clear that in case of a hip drop (or Trendelenburg gait), the contralateral knee angle at
                  midswing will be compromised.
                  <br></br>
                  <br></br>
                  <strong>Causes low knee flexion at midswing</strong>
                  <ul>
                    <li>Reduced hip extension at terminal stance</li>
                    <li>Poor knee extension at pre-swing</li>
                    <li>Poor knee flexion / ankle plantar flexion at toe off</li>
                  </ul>
                </div>
              </IonCol>
            </IonRow>
          </IonGrid>
        </IonSlide>
      </IonSlides>
    </>
  );
};
