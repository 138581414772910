/** Overview report tab showing report metadata */
import { IonChip, IonContent, IonIcon, IonItem, IonLabel, IonList, IonPage, IonText } from '@ionic/react';
import { bodyOutline, calendarOutline, cloudUploadOutline, pricetagsOutline } from 'ionicons/icons';
import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import { Report, ReportType } from '../../model';

type OverviewReportProps = RouteComponentProps & {
  report: Report;
};

/** Overview report tab showing report metadata
 * @param report - The full report data, including reportInfo data
 */
export const OverviewReportTab = ({ report }: OverviewReportProps) => {
  const data = report.data;
  const activity = data?.reportType === ReportType.RUN ? 'Run' : 'Walk';

  return (
    <IonPage>
      <IonContent className='ion-padding'>
        <IonText>
          <h1>Report</h1>
          <h6>
            <em>{report.id}</em>
          </h6>
        </IonText>
        <IonList lines='none'>
          <IonItem>
            <IonIcon icon={calendarOutline} slot='start' />
            <IonLabel>{report.uploadTimestamp.toLocaleString()}</IonLabel>
          </IonItem>

          <IonItem>
            <IonIcon icon={cloudUploadOutline} slot='start' />
            <IonLabel>{report.filename}</IonLabel>
          </IonItem>

          {report.tags.length > 0 && (
            <IonItem>
              <IonIcon icon={pricetagsOutline} slot='start' />
              {report.tags.map((tag) => (
                <IonChip key={tag}>{tag}</IonChip>
              ))}
            </IonItem>
          )}

          {activity && (
            <IonItem>
              <IonIcon icon={bodyOutline} slot='start' />
              <IonLabel>Activity: {activity}</IonLabel>
            </IonItem>
          )}
        </IonList>
      </IonContent>
    </IonPage>
  );
};
